const ru = {
  locale: 'ru-RU',
  messages: {
    'inputVinBrand': 'Пожалуйста, введите номер рамы/номер запчастей/марка',
    'history': 'История',
    'home': 'Главн страниц',
    'catalog': 'Поиск модели',
    'search': 'Искать',
    'dragFile': 'Вставьте или перетащите сюда файлы для идентификации',
    'selectImage': 'Выбрать изображение',
    'howWorks': 'Узнайте, как это работает',
    'more': 'Больше',
    'helpSection': 'Помочь',
    'freeTrialDay': 'Три дня испытательного срока',
    'account': 'Личный',
    'purchase': 'Покупка',
    'message': 'Сообщение',
    'favorites': 'Избранное',
    'feedbackMessage': 'Сообщение об обратной связи',
    'watch': 'Список избранного',
    'coupon': 'Купоны',
    'signOut': 'Выйти',
    'register': 'Регистрация',
    'subscribe': 'Подписаться',
    'freeSearch': 'Бесплатный поиск',
    'inviteTips': 'Нажмите и пригласите 10 друзей на бесплатные ежегодные карточки',
    'invite': 'Пригласить',
    'freeTrialTips': "Хотите использовать partsbond, начните бесплатную пробную версию",
    'freeTrial': 'Бесплатная пробная версия',
    'helpVideos': 'Помощь видео',
    'copyright': 'Copyright@2023 AutoBond Inc.All Rights Reserved',
    'api': 'API',
    'app': 'App',
    'about': 'О нас',
    'contact': 'Свяжитесь с нами',
    'and': 'и',
    'privacy': 'Конфиденциальность и условия',
    'feedback': 'Обратная связь',
    'writeOpinion': 'Напишите свое мнение',
    'sentUsEmail': 'Почтовый адрес : aaronw@autobondinc.com',
    'sentUs': 'Свяжитесь с нами',
    'iosDownload': 'Загрузка на IOS',
    'androidDownload': 'Загрузка на Android',
    'inputPNMR': 'Пожалуйста, введите номер детали/имя/модель/примечание',
    'view3D': 'Посмотреть 3d модель',
    'again': 'Еще раз',
    'pictures': 'Картинки',
    'partsNumber': 'Номер детали',
    'partsName': 'Название детали',
    'priceRange': 'Диапазон цен',
    'operation': 'операцион',
    'email': 'Почта',
    'vCode': 'Код подтверждения',
    'nPassword': 'Новый пароль',
    'cNPassword': 'Установить новый пароль',
    'submit': 'Отправить',
    'getCode': 'Получить код',
    'countdown': '{num}s',
    'inputEmail': 'Пожалуйста, введите электронной почты',
    'emailCorrectly': 'Пожалуйста, правильно заполните электронное письмо',
    'inputPassword': 'Введите пароль',
    'passwordsInconsistent': 'Два введенных пароля не совпадают',
    'sendCodeSuccess': 'Отправлено',
    'agreeUserAgreement': 'Согласитесь с пользовательским соглашением',
    'tryFree': 'Бесплатная пробная версия',
    'tryFreeDay': 'После входа в систему.Нажмите на бесплатную пробную версию, чтобы получить 7-дневную пробную версию',
    'registrationCodeSentSuccess': 'Регистрационный код отправлено! Если вы не получили код в течение 1-2 минут, попробуйте обновить почту и проверить папку "Спам"',
    'findUs': 'Как нас найти',
    'iAgree': 'Пользовательское соглашение',
    'userAgreement': 'Пользовательское соглашение',
    'brand': 'Бренд',
    'sampleSearch': 'Поиск образцов',
    'passwordChangedSuccess': 'Код изменен',
    'passwordChangedFailed': 'Не удалось изменить пароль',
    'enterNewName': 'Введите новое имя пользователя',
    'userChangedSuccess': 'Пользовательская модификация удалась',
    'userChangedFailed': 'Изменение информации пользователя не удалось',
    'fillName': 'Пожалуйста, сначала напишите имя пользователя',
    'vinBindingSuccee': 'Номер рамы автомобиля Привязка успешна',
    'success': 'Успешно',
    'brandPackage': 'Тарифы поиска марок автомобилей',
    'combo': 'Тарифы',
    'validTime': 'Эффективное время',
    'cancelRenewal': 'Отменить обновление',
    'packageContent': 'Тарифы',
    'bindFrame': 'Привязать еще одну учетную запись',
    'remainingQueries': 'Оставшиеся запросы',
    'personalInformation': 'Личный кабинет',
    'loginAccount': 'Логин',
    'nickName': 'Прозвище',
    'modify': 'Исправить',
    'bindingEmail': 'Привязка электронной почты',
    'loginPassword': 'Логин',
    'enterContent': 'Введите содержимое',
    'noCoupons': 'Купонов нет',
    'discountOperation': 'Скидки',
    'preferentialContent': 'Предлагайте контент',
    'serviceLife': 'Срок службы',
    'user': 'пользовател',
    'remarks': 'Заметка',
    'noPartsCollection': 'Нет запчастей',
    'selectAll': 'Выбрать все.',
    'delete': 'Удалить',
    'vin': 'Номер рамы',
    'oe': 'OE',
    'detail': 'Информация',
    'noNews': 'Нет новости',
    'feedbackDetails': 'Отзывы',
    'feedbackStatus': 'Статус обратной связи',
    'feedbackTime': 'Время Отзывы',
    'questionType': 'Тип проблемы',
    'problemDescription': 'Описание проблемы',
    'solved': 'Проблема решена',
    'pending': 'Ждать',
    'supplementaryQuestion': 'Дополнительный вопрос',
    'endFeedback': 'Конец обратной связи',
    'cancel': 'Отменить',
    'invalidCoupon': "Код скидки не найден",
    'bindVin': 'Привязь номер {num} к раме',
    'annualSubscribe': 'Годовая подписка',
    'currencySymbol': '$',
    'enterNumber': 'Пожалуйста, введите по одному номеру',
    'boundRemaining': '{hasBound}/{remainingBound} Номер рамы привязан [{has}/{remaining} Привяжите остальные номера рамок]',
    'boundDescribe1': 'К одной учетной записи можно применить несколько тарифы',
    'boundDescribe2': 'Подписка действительна в течение одного года(начинается с даты покупки)',
    'boundDescribe3': 'Vin может быть навсегда привязан к вашей учетной записи; если вы хотите привязать vin позже, вы можете сделать это в профиле сведений об учетной записи',
    'allInOne': 'ALL-IN-ONE',
    'asiaPack': 'ASIA PACK',
    'euroPack': 'EURO PACK',
    'usPack': 'US PACK',
    'daySubscription': 'на день',
    'monthSubscription': 'на месяц',
    'annualSubscription': 'на год',
    'use': 'Использовать',
    'howGetCode': 'Как получить код скидки',
    'payPalWith': 'PayPal with',
    'openingPermission': 'Открытая власть',
    'availablePackages': 'Доступные тарифы',
    'bestMatch': 'Лучший вариант',
    'priceLowHigh': 'Цена от низкой к высокой',
    'priceHighLow': 'Цена от высокой к низкой',
    'pleaseLoginFirst': 'Пожалуйста, сначала войдите в систему',
    'here3DArea': 'Это область 3-D',
    'applicableVehicles': 'Применяемые модели',
    'applicableVehicle': 'Подходит для моделей',
    'interchangeableParts': 'Запасная деталь',
    'condition': 'Условия',
    'all': 'все',
    'channel': 'Канал',
    'location': 'Место',
    'partsFound': 'Количество деталей',
    'noData': 'Данные не доступны',
    'carsModels': 'Тип автомобиля(возраст)',
    'year': 'год',
    'market': 'Рынок',
    'oeNumber': 'Номер OE',
    'partName': 'Название детали',
    'count': 'Счет',
    'partsPrice': 'Сравнение цен на детали',
    'directReplacement': 'Прямая замена',
    'indirectReplacement': 'Косвенная замена',
    'priceTrend': 'Динамика цен',
    'partPicture': 'Фотографии деталей',
    'brands': 'бренд',
    'replacement': 'Замена',
    'applicableVeh': 'Подходит для veh',
    'supplier': 'Поставщик',
    'getIt': 'Получить',
    'go': 'прыга',
    'findResults': 'Найти результаты для {num}',
    'partOe': 'OE',
    'mainGroup': 'Основная группа',
    'subGroup': 'Подгруппа',
    'group': 'Подгруппа',
    'configDetails': 'Информация о конфигурации',
    'selectGroup': 'Выбрать группу',
    'conflg': 'Выбор конфигурации',
    'picture': 'Картинка',
    'mg': 'MG',
    'sg': 'SG',
    'illNo': 'ILL-NO',
    'name': 'Имя',
    'remark': 'Заметка',
    'model': 'модел',
    'selectConfigurationInformation': 'Выбрать Детали конфигурации',
    'groupOne': 'Первая группа !',
    'lastGroup': 'Последняя группа. !',
    'list': 'Объявления',
    'back': 'Назад',
    'switchGroup': 'переходить',
    'notFilter': 'Не отфильтрованный',
    'filter': 'Настройка',
    'config': 'конфигурац',
    'pos': 'POS',
    'qty': 'QTY',
    'addWatch': 'Добавить в избранное',
    'brandPart': 'Марки детали',
    'component': 'компонент',
    'disclaimer': 'Примечание:Результаты запросов приведены только для справки',
    'partDetail': 'Детальная информация',
    'vinNotAvailable': 'Идентификационный номер автомобиля недоступен',
    'partNotAvailable': 'Номер детали не доступен',
    'lackModels': 'Отсутствующие модели',
    'dataNotComplete': 'Неполные данные',
    'dataError': 'Ошибка в данных',
    'dataNotNew': 'Данных недостаточно',
    'slowSpeed': 'Медленная скорость',
    'noUniqueness': 'Не единственный',
    'other': 'Другие',
    'selectQuestion': 'Выберите вопрос',
    'close': 'Закрывать',
    'howVin': 'Как узнать номер рамы',
    'howVin1': 'Найдите номер рамы и запишите VIN на стекле переднего окна кузова автомобиля',
    'howVin2': 'Введите номер рамы или нажмите, чтобы загрузить изображение для определения номера рама',
    'howVin3': 'Нажмите на значок запроса, чтобы узнать номер рамы',
    'howVin4': 'Введите название бренда и нажмите на значок(иконку) для запроса',
    'findParts': 'Как найти запчасти?',
    'findParts1': 'Наведите мышку на номер рамы, чтобы просмотреть основную информацию о конфигурации',
    'findParts2': 'Нажмите , чтобы найти детали кузова, шасси и двигателя с помощью 3D-моделей',
    'findParts3': 'Выберите основную группу и подгруппу для ввода каталог деталей',
    'findParts4': 'Нажмите, чтобы повторно выбрать основную группу кластеров и запрос номер рама',
    'findParts5': 'Нажмите, чтобы выбрать деталь по номеру детали на изображении',
    'howPart': 'Как использовать поиск по номеру детал',
    'howPart1': 'Введите номер детали или загрузите ее изображение, нажмите на кнопку поиска.',
    'howPart2': 'Нажмите , чтобы сохранить деталь в мою коллекцию, и вы получите уведомление по электронной почте, когда цена на деталь изменится!',
    'howPart3': 'Нажмите на иконку, чтобы увидеть последние тенденции цен на детали',
    'noHelp': 'Не нужна помощь',
    'understood': 'Понятно',
    'nextStep': 'Далее',
    'clickCheckInvitation': 'Нажмите здесь, чтобы узнать о приглашениях',
    'inviteFriendsGetPackage': 'Пригласите 10 друзей и получите годовой пакет(тариф), стоимость которого составляет 540 долларов!',
    'emailAddress': 'Электронная почта',
    'status': 'Состояние',
    'redeem': 'Обмен',
    'inviteRewardTips': 'Пожалуйста, подтвердите ваше заявление—ежегодная покупка—Если у вас есть активный пакет, ваши вознаграждения будут автоматически выкуплены по истечении срока их действия!',
    'currentSubscriptionExpires': 'Срок действия вашего тариф истекает в',
    'confirm': 'Да',
    'putAway': 'Убрать',
    'seeAll': 'Увидеть все',
    'pageCannotEmpty': 'Номер страницы не может быть пустым',
    'pleaseRegisterSignin': 'Пожалуйста, войдите, чтобы найти больше данных об автомобиле',
    'price': 'Цена',
    'quantity': 'Количества',
    'pleaseRL': 'Пожалуйста, зарегистрируйтесь и войдите',
    'sign': 'Войти',
    'stay': 'Запоминать пароль',
    'forget': 'Забудь пароль',
    'password': 'Пароль',
    'RFFVS': 'Зарегистрируйтесь для бесплатного поиска vin',
    'cName': 'Название компании',
    'basic': 'Основная информация',
    'updateTime': 'Время обновления',
    'OfficialStore': 'Официальный магазин',
    'english': 'English',
    'russian': 'Русский',
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語',
    'china': '简体中文',
    'cht': '繁體中文'
  }
}

export default ru