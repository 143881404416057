import React from 'react';
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from "react-router-dom"
import ReactGA from 'react-ga4';
import reportWebVitals from './reportWebVitals'
import Main from '@/page/Main'
import en from './locale/en'
import ru from './locale/ru'
import id from './locale/id'
import th from './locale/th'
import vi from './locale/vi'
import ms from './locale/ms'
import ar from './locale/ar'
import es from './locale/es'
import fr from './locale/fr'
import pt from './locale/pt'
import tr from './locale/tr'
import jp from './locale/jp'
import ko from './locale/ko'
import zh from './locale/zh'
import cht from './locale/cht'
import './index.scss'
const w: any = window
const lang: any = {
  en,
  ru,
  id,
  th,
  vi,
  ms,
  ar,
  es,
  fr,
  pt,
  tr,
  jp,
  ko,
  zh,
  cht
}

ReactGA.initialize('G-1TC3XESR0L');
ReactDOM.render(
  <BrowserRouter basename="/">
    <IntlProvider locale={lang[w.hostname] ? lang[w.hostname].locale : lang['en'].locale} messages={lang[w.hostname] ? lang[w.hostname].messages : lang['en'].messages}>
      <Main />
    </IntlProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
