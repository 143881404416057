const jp = {
  locale: 'ja-jp',
  messages: {
    "inputVinBrand": "フレーム番号/部品番号/ブランドを入力してください",
    "history": "履歴",
    "home": "トップページ",
    "catalog": "車種照会",
    "search": "検索けんさく",
    "dragFile": "この領域にファイルを貼り付けまたはドラッグして識別します",
    "selectImage": "画像を選択",
    "howWorks": "どのように機能しているかを理解する",
    "more": "その他",
    "helpSection": "ヘルプ",
    "freeTrialDay": "3日間の無料トライアル",
    "account": "アカウント",
    "purchase": "購入する",
    "message": "メッセージ",
    "favorites": "コレクション",
    "feedbackMessage": "フィードバックメッセージ",
    "watch": "コレクションリスト",
    "coupon": "クーポン券",
    "signOut": "ログアウト",
    "register": "登録",
    "subscribe": "購読",
    "freeSearch": "無料検索",
    "inviteTips": "ボタンをクリックして10人の友達を無料年間カードに招待する",
    "invite": "に勧める",
    "freeTrialTips": "Partsbondを引き続き使用するには、無料トライアルを開始してください",
    "freeTrial": "無料トライアル",
    "helpVideos": "ヘルプビデオ",
    "copyright": "Copyright@2020 AutoBond Inc.All Rights Reserved",
    "api": "API",
    "app": "App",
    "about": "私たちについて",
    "contact": "連絡先",
    "and": "と",
    "privacy": "プライバシーと条件",
    "feedback": "フィードバック",
    "writeOpinion": "あなたの意見を書いて…",
    "sentUsEmail": "メールアドレス：aaronw@autobondinc.com",
    "sentUs": "連絡先",
    "iosDownload": "IOSダウンロード",
    "androidDownload": "Androidダウンロード",
    "inputPNMR": "部品番号/名前/型番/備考を入力してください",
    "view3D": "クリックして3 Dモデルを表示",
    "again": "もう一度",
    "pictures": "画像",
    "partsNumber": "部品番号",
    "partsName": "部品名",
    "priceRange": "価格帯",
    "operation": "操作",
    "email": "メールボックス",
    "vCode": "認証コード",
    "nPassword": "新しいパスワード",
    "cNPassword": "新しいパスワードの決定",
    "submit": "送信",
    "getCode": "認証コードの取得",
    "countdown": "{num}s",
    "inputEmail": "メールアドレスを入力してください",
    "emailCorrectly": "メールを正しく記入してください",
    "inputPassword": "パスワードを入力してください",
    "passwordsInconsistent": "入力された2つのパスワードが一致しません",
    "sendCodeSuccess": "送信成功",
    "agreeUserAgreement": "『ユーザープロトコル』に同意してください",
    "tryFree": "無料トライアル",
    "tryFreeDay": "ログイン後、無料トライアルをクリックして7日間のトライアルを取得",
    "registrationCodeSentSuccess": "登録コードの送信に成功しました！1～2分以内にコードを受け取っていない場合は、受信トレイを更新してスパムフォルダを確認してみてください。",
    "findUs": "どうやって見つけた?",
    "iAgree": "同意します",
    "userAgreement": "ユーザプロトコル",
    "brand": "ブランド",
    "sampleSearch": "サンプル検索",
    "passwordChangedSuccess": "パスワード変更に成功しました",
    "passwordChangedFailed": "パスワードの変更に失敗しました",
    "enterNewName": "新しいユーザー名を入力してください",
    "userChangedSuccess": "ユーザーの変更に成功しました",
    "userChangedFailed": "ユーザー情報の変更に失敗しました",
    "fillName": "まずユーザー名を記入してください",
    "vinBindingSuccee": "フレーム番号のバインドに成功しました",
    "success": "成功",
    "brandPackage": "自動車ブランド照会コース",
    "combo": "セット",
    "validTime": "ゆうこうじかん",
    "cancelRenewal": "更新のキャンセル",
    "packageContent": "セット",
    "bindFrame": "もう1つの番号をバインド",
    "remainingQueries": "残りのクエリ数",
    "personalInformation": "個人情報",
    "loginAccount": "ログインアカウント",
    "nickName": "別名＃ベツメイ＃",
    "modify": "変更",
    "bindingEmail": "バインドメールボックス",
    "loginPassword": "ログインパスワード",
    "enterContent": "入力内容",
    "noCoupons": "クーポンはございません",
    "discountOperation": "割引アクション",
    "preferentialContent": "特典内容",
    "serviceLife": "しようじゅみょう",
    "user": "ユーザー",
    "remarks": "コメント",
    "noPartsCollection": "部品がありません",
    "selectAll": "すべて選択",
    "delete": "削除＃サクジョ＃",
    "vin": "フレーム番号",
    "oe": "OE",
    "detail": "詳細",
    "noNews": "新しい情報はありません",
    "feedbackDetails": "フィードバック情報",
    "feedbackStatus": "フィードバック状態",
    "feedbackTime": "きかんじかん",
    "questionType": "質問タイプ",
    "problemDescription": "問題の説明",
    "solved": "解決済み",
    "pending": "待機中",
    "supplementaryQuestion": "補足的な問題",
    "endFeedback": "エンドフィードバック",
    "cancel": "キャンセル",
    "invalidCoupon": "この割引コードが見つかりませんでした",
    "bindVin": "バインド{num}フレーム番号",
    "annualSubscribe": "年間購読",
    "currencySymbol": "$",
    "enterNumber": "1つの番号を順番に入力してください",
    "boundRemaining": "{hasBound}/{remainingBound}フレーム番号バインド[{has}/{remaining}残りのフレーム番号バインド]",
    "boundDescribe1": "複数のサブスクリプションを1つのアカウントに適用できます",
    "boundDescribe2": "購読の有効期間は1カレンダ年（購入日から）",
    "boundDescribe3": "VINはアカウントに永続的にバインドできます。後でVINをバインドする場合は、アカウントの詳細内でバインドできます",
    "allInOne": "ALL-IN-ONE",
    "asiaPack": "ASIA PACK",
    "euroPack": "EURO PACK",
    "usPack": "US PACK",
    "daySubscription": "日単位で購読",
    "monthSubscription": "月別購読",
    "annualSubscription": "年単位で購読する",
    "use": "使用",
    "howGetCode": "割引コードの取得方法",
    "payPalWith": "PayPal with",
    "openingPermission": "オープンアクセス権",
    "availablePackages": "利用可能なコース",
    "bestMatch": "最適な一致",
    "priceLowHigh": "低価格から高価格へ",
    "priceHighLow": "価格は高いものから低いものへ",
    "pleaseLoginFirst": "先にログインしてください",
    "here3DArea": "これは3 D領域です",
    "applicableVehicles": "適用車種",
    "applicableVehicle": "自動車に適用",
    "interchangeableParts": "置換",
    "condition": "条件＃ジョウケン＃",
    "all": "すべて",
    "channel": "ルート本",
    "location": "位置",
    "partsFound": "個の部品",
    "noData": "データなし",
    "carsModels": "車種（年代）",
    "year": "年",
    "market": "市場",
    "oeNumber": "OE番号",
    "partName": "部品名",
    "count": "数",
    "partsPrice": "部品価格比",
    "directReplacement": "直接置換",
    "indirectReplacement": "間接置換",
    "priceTrend": "価格トレンド",
    "partPicture": "部品画像",
    "brands": "ブランド",
    "replacement": "置換",
    "applicableVeh": "適用Veh",
    "supplier": "サプライヤ",
    "getIt": "取得＃シュトク＃",
    "go": "Go",
    "findResults": "{num}に関する結果を検索します",
    "partOe": "OE",
    "mainGroup": "マスターグループ",
    "subGroup": "グループ化",
    "group": "グループ化",
    "configDetails": "構成の詳細",
    "selectGroup": "グループの選択",
    "conflg": "構成",
    "picture": "画像",
    "mg": "MG",
    "sg": "SG",
    "illNo": "ILL-NO",
    "name": "の名前をあげる",
    "remark": "コメント",
    "model": "モデル",
    "selectConfigurationInformation": "構成情報の選択",
    "groupOne": "第一グループ！",
    "lastGroup": "最後のグループ！",
    "list": "リスト",
    "back": "リターンマッチ",
    "switchGroup": "スイッチ",
    "notFilter": "フィルタなし",
    "filter": "フィルタ",
    "config": "構成",
    "pos": "POS",
    "qty": "QTY",
    "addWatch": "コレクションリストに追加",
    "brandPart": "ブランド部品",
    "component": "コンポーネント",
    "disclaimer": "宣言：クエリ結果は参照用です。",
    "partDetail": "部品の詳細",
    "vinNotAvailable": "車両識別コードは使用できません",
    "partNotAvailable": "部品番号は使用できません",
    "lackModels": "モデルがありません",
    "dataNotComplete": "データ不完全",
    "dataError": "データエラー",
    "dataNotNew": "データが新しくない",
    "slowSpeed": "スピードが遅い",
    "noUniqueness": "ユニークではありません",
    "other": "その他",
    "selectQuestion": "問題の選択",
    "close": "閉じる",
    "howVin": "ラック番号の表示方法",
    "howVin1": "フレーム番号を見つけ、車体フロントガラスに車両識別コードを記録する",
    "howVin2": "フレーム番号を入力するか、アップロード画像をクリックしてフレーム番号を識別します",
    "howVin3": "クエリーアイコンをクリックしてフレーム番号をクエリーするには",
    "howVin4": "ブランド名を入力し、問合せアイコンをクリックして問合せを行う",
    "findParts": "部品を見つける方法",
    "findParts1": "基本的な構成情報を表示するために、駐車枠番号にマウスを置きます",
    "findParts2": "このアイコンをクリックして3 Dモデルを使用して車体部品、シャーシ部品、エンジン部品を検索します",
    "findParts3": "部品リストにマスターグループとグループを選択",
    "findParts4": "をクリックして、メイングループグループとボックス番号クエリーを再選択します",
    "findParts5": "分解ビューの部品番号をクリックして部品を選択します",
    "howPart": "部品番号照会を使用する方法",
    "howPart1": "画像識別部品番号を入力またはアップロードし、検索をクリックして検索します",
    "howPart2": "アイコンをクリックして部品を私のコレクションに集めます。部品の価格が変化すると、メール通知が届きます",
    "howPart3": "アイコンをクリックして、部品の最近の価格トレンドを表示します",
    "noHelp": "ヘルプは不要",
    "understood": "わかりました",
    "nextStep": "次のステップ",
    "clickCheckInvitation": "招待ステータスを表示するには、ここをクリックしてください",
    "inviteFriendsGetPackage": "10人の友達を招待して年間コースを獲得して、540ドルの価値があります！",
    "emailAddress": "Eメールアドレス",
    "status": "ステータス",
    "redeem": "換算",
    "inviteRewardTips": "<p>お申し込みをご確認ください<br/>＜em＞All-in-One年間購入</p><p>ご褒美</p><p>現在有効なコースがあれば、ご褒美は満期になると自動的に両替されます。</p>",
    "currentSubscriptionExpires": "現在のコースの有効期限は",
    "confirm": "確認",
    "putAway": "Put Away",
    "seeAll": "See All",
    "pageCannotEmpty": "ページ数を空白にすることはできません",
    "pleaseRegisterSignin": "詳細な車両データを検索するために登録/ログインしてください",
    "price": "価格",
    "quantity": "数量",
    "pleaseRL": "登録後にログインしてください",
    "sign": "ログイン＃ログイン＃",
    "stay": "パスワードを記憶する",
    "forget": "パスワードを忘れる",
    "password": "パスワード",
    "RFFVS": "無料検索vinの登録",
    "cName": "会社名",
    "basic": "基礎情報",
    "updateTime": "更新日時",
    'OfficialStore': 'オフィシャルショップ',
    "english": "English",
    "russian": "русск",
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語',
    'china': '简体中文',
    'cht': '繁體中文'
  }
}

export default jp