import React, { useState, useEffect, useContext, useImperativeHandle } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Tooltip from 'rc-tooltip'
import MainContext from '@/context/MainContext'
import { PurchaseModal, NewPurchase } from '@/page/My'
import { Login } from '@/page/Login'
import Feedback from '@/components/Feedback'
import HelpVideo from '@/components/HelpVideo'
import Invite, { Invited } from '@/components/Invite'
import accountApi from '@/api/account'
import { getUrlParams } from '@/utils/utils'
import css from './main.module.scss'
import logoImg from '@/assets/images/logo.png'
import moneyTag from '@/assets/images/moneyTag.png'
import Modal from '@/components/Modal'

import lang_english from '@/assets/images/lang_english.png'
import lang_russian from '@/assets/images/lang_russian.png'
import lang_indonesian from '@/assets/images/lang_indonesian.png'
import lang_vietnamese from '@/assets/images/lang_vietnamese.png'
import lang_malaysia from '@/assets/images/lang_malaysia.png'
import lang_thai from '@/assets/images/lang_thai.png'
import lang_arabia from '@/assets/images/lang_arabia.png'
import lang_spain from '@/assets/images/lang_spain.png'
import lang_portugal from '@/assets/images/lang_portugal.png'
import lang_france from '@/assets/images/lang_france.png'
import lang_Turkey from '@/assets/images/lang_Turkey.png'
import lang_japanese from '@/assets/images/lang_japanese.png'
import lang_korean from '@/assets/images/lang_korean.png'
import lang_china from '@/assets/images/lang_china.png'


interface Parameter {
  type?: string,
  tipsVisible?: boolean,
  setTipsVisible?: Function,
  onRef?: any
}

const lang: any = {
  en: {
    text: 'english',
    logo: lang_english
  },
  ru: {
    text: 'russian',
    logo: lang_russian
  },
  id: {
    text: 'indonesian',
    logo: lang_indonesian
  },
  vi: {
    text: 'vietnamese',
    logo: lang_vietnamese
  },
  ms: {
    text: 'malaysia',
    logo: lang_malaysia
  },
  th: {
    text: 'thai',
    logo: lang_thai
  },
  ar: {
    text: 'arabia',
    logo: lang_arabia
  },
  es: {
    text: 'spain',
    logo: lang_spain
  },
  pt: {
    text: 'portugal',
    logo: lang_portugal
  },
  fr: {
    text: 'france',
    logo: lang_france
  },
  tr: {
    text: 'Turkey',
    logo: lang_Turkey
  },
  jp: {
    text: 'japanese',
    logo: lang_japanese
  },
  ko: {
    text: 'korean',
    logo: lang_korean
  },
  zh: {
    text: '简体中文',
    logo: lang_china
  },
  cht: {
    text: '繁體中文',
    logo: lang_china
  }
}
const w: any = window

const Header: React.FC<Parameter> = (props: Parameter) => {
  const { tipsVisible, setTipsVisible, onRef } = props
  const intl = useIntl()
  const { buy } = getUrlParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const { store, setStore } = useContext(MainContext)
  const { userInfo, purchaseVisible, loginVisible, freecount } = store
  const [ feedbackVisible, setFeedbackVisible ] = useState<boolean>(false)
  
  const [ vedioVisible, setVedioVisible ] = useState<boolean>(false)
  const [ vedioIndex, setVedioIndex ] = useState<number>(0)

  const [ inviteVisible, setInviteVisible ] = useState<boolean>(false)

  const [ numberVisible, setNumberVisible ] = useState<boolean>(false)
  const [ freeTips, setFreeTips ] = useState<boolean>(window.localStorage.getItem('freeTips') ? false : true)

  const [buyVisible, setBuyVisible] = useState<boolean>(false)
  const menu = [
    {
      title: intl.formatMessage({id: 'home'}),
      path: '/home'
    },
    {
      title: intl.formatMessage({id: 'catalog'}),
      path: '/catalog',
      special: ['vin', 'search', 'group']
    },
    {
      title: intl.formatMessage({id: 'partsPrice'}),
      path: '/partsprice'
    },
    {
      title: intl.formatMessage({ id: 'OfficialStore' }),
      path: '/officialStore'
    }
  ]
  useEffect(()=>{
    if(!freeTips){
      window.localStorage.setItem('freeTips', '1')
    }
  }, [freeTips])
  
  const user = (
    <>
      <p><Link to="/my/account"><i className='iconfont iconwode1'/> {intl.formatMessage({id: 'account'})}</Link></p>
      <p><Link to="/my/purchase"><i className='iconfont icongoumai'/> {intl.formatMessage({id: 'purchase'})}</Link></p>
      <p><Link to="/my/message"><i className='iconfont iconxiaoxi'/> {intl.formatMessage({id: 'message'})}</Link></p>
      <p><Link to="/my/favorite"><i className='iconfont iconshoucangmoren'/> {intl.formatMessage({id: 'watch'})}</Link></p>
      <p><Link to="/my/coupon"><i className='iconfont iconbaojiadan'/> {intl.formatMessage({id: 'coupon'})}</Link></p>
      {/* <p><Link to="/my/account">{userInfo.data?.full_name || userInfo.data?.username}</Link></p> */}
      <p className="out" onClick={()=>logout()}><i className='iconfont icontuichu11'/> {intl.formatMessage({id: 'signOut'})}</p>
    </>
  )

  const language = (
    <>
      <p><a href="https://zh.partsbond.com" className='flex-s-c-i'><img src={lang_china} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'china'})}</a></p>
      {/* <p><a href="https://cht.partsbond.com" className='flex-s-c-i'><img src={lang_china} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'cht'})}</a></p> */}
      <p><a href="https://partsbond.com" className='flex-s-c-i'><img src={lang_english} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'english'})}</a></p>
      <p><a href="https://ru.partsbond.com" className='flex-s-c-i'><img src={lang_russian} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'russian'})}</a></p>
      <p><a href="https://id.partsbond.com" className='flex-s-c-i'><img src={lang_indonesian} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'indonesian'})}</a></p>
      <p><a href="https://vi.partsbond.com" className='flex-s-c-i'><img src={lang_vietnamese} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'vietnamese'})}</a></p>
      <p><a href="https://ms.partsbond.com" className='flex-s-c-i'><img src={lang_malaysia} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'malaysia'})}</a></p>
      <p><a href="https://th.partsbond.com" className='flex-s-c-i'><img src={lang_thai} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'thai'})}</a></p>
      <p><a href="https://ar.partsbond.com" className='flex-s-c-i'><img src={lang_arabia} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'arabia'})}</a></p>
      <p><a href="https://es.partsbond.com" className='flex-s-c-i'><img src={lang_spain} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'spain'})}</a></p>
      <p><a href="https://pt.partsbond.com" className='flex-s-c-i'><img src={lang_portugal} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'portugal'})}</a></p>
      <p><a href="https://fr.partsbond.com" className='flex-s-c-i'><img src={lang_france} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'france'})}</a></p>
      <p><a href="https://tr.partsbond.com" className='flex-s-c-i'><img src={lang_Turkey} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'Turkey'})}</a></p>
      <p><a href="https://jp.partsbond.com" className='flex-s-c-i'><img src={lang_japanese} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'japanese'})}</a></p>
      <p><a href="https://ko.partsbond.com" className='flex-s-c-i'><img src={lang_korean} style={{ height: 15, marginRight: 5 }} />{intl.formatMessage({id: 'korean'})}</a></p>
    </>
  )

  const msg = (
    <div className='msg-content'>
      {
        userInfo.msg?.map((item: any, index: number)=>{
          return (
            item.code === 'pre_freetrail' ? (
              <p key={index}>{item.content} <button onClick={()=>setStore({value: true, type: 'purchaseVisible'})}>{intl.formatMessage({id: 'subscribe'})}</button></p>
            ): (
              <p key={index}>{item.content}</p>
            )
          )
        })
      }
    </div>
  )

  const logout = () => {
    accountApi.logout().then((result: {code: number}) => {
      if(result.code === 1) {
        window.location.href = '/'
      }
    })
  }
  const go = (path: string) => {
    // setStore({value: [], type: 'crumb'})
    // window.sessionStorage.removeItem('groupCrumb')
    // window.sessionStorage.removeItem('crumb')
    if(buy){
      window.sessionStorage.setItem('buycode', buy)
    }
    if(path !== '/officialStore') {
      history.push(path)
    }else {
      let user_name = userInfo && userInfo.data && userInfo.data.username
      let host = window.location.host
      if(host.includes("multilingual")) {
        window.open(`https://webtest.maiqipei.com/home?user_name=${user_name}`, "__blank")
      }else {
        let lang = 'en'
        if(host.includes("ru")) {
          lang = 'ru'
        }
        window.open(`https://partsbond.maiqipei.com/home?user_name=${user_name}&partsboundLang=${lang}`, "__blank")
      }
      
    }
    
  }

  
  useImperativeHandle(onRef, () => ({
    setInviteVisible
  }))
  return (
    <div>
      <div className={css['bg']}>
        <div className={css['header']}>
          <div className={css['logo']}>
            <Link to="/"><img src={logoImg} alt="logo" className={css['min']} /></Link>
            {
              menu.map((item, index)=>{
                return (
                  <span key={index} onClick={()=>go(item.path)} className={((pathname.indexOf(item.path) !== -1 || (item.special?.filter((special)=>pathname.indexOf(special) !== -1))?.length) && item.path !== '/') ? css['active'] : ''}>{item.title}</span>
                )
              })
            }
          </div>
          <div className={css['login']}>
            {/* {
              userInfo.userid ? (
                <div className={css['tobuy']} onClick={() => setBuyVisible(true)}>
                  {intl.formatMessage({ id: 'purchase' })}
                  <img className={css['icon']} src={moneyTag} style={{ height: 14 }}  />
                </div>
              ): null
            } */}
            <div className={css['tobuy']} onClick={() => setBuyVisible(true)}>
              {intl.formatMessage({ id: 'purchase' })}
              <img className={css['icon']} src={moneyTag} style={{ height: 14 }}  />
            </div>
            <Tooltip placement="bottom" overlay={language} overlayStyle={{width: 155}} overlayClassName="white" trigger="click" >
              <span className="flex-s-c-i">
                <img src={lang[w.hostname]?.logo || lang_english} style={{ height: 16, marginRight: 5 }} />
                {intl.formatMessage({id: (lang[w.hostname]?.text || 'english')})} <i className="iconfont icondown"></i>
              </span>
            </Tooltip>
            {
              userInfo.userid ? (
                <>
                  {
                    freecount.status ? (
                      <div className={css.freecount}>
                        <em>{freecount.count}</em>
                        <p>{intl.formatMessage({id: 'freeSearch'})}</p>
                      </div>
                    ): null
                  }
                  
                  {
                    userInfo.data?.trialdesc ? <button className={css.tried}>{userInfo.data?.trialdesc}</button> : null
                  }
                  
                  {
                    !userInfo.data?.redeemed ? (
                      <Tooltip visible={tipsVisible} overlayClassName="newred" mouseLeaveDelay={0} placement="bottom" overlay={(<div className='tips'><p>{intl.formatMessage({id: 'inviteTips'})}</p><span className="iconfont icona-16pt1 btn" onClick={()=>{setTipsVisible && setTipsVisible(false)}}></span></div>)} overlayStyle={{width: 270}} >
                        <button onClick={()=>setInviteVisible(true)} id="invite" className={css.red}><i className='iconfont icona-16pt' /> {intl.formatMessage({id: 'invite'})} {numberVisible ? <span className={css.number}>1</span> : null}</button>
                      </Tooltip>
                    ): null
                  }
                  {
                    // !userInfo.data?.trialused ? (
                    //   <Tooltip visible={freecount.status === 0 && freeTips} overlayClassName="newred" mouseLeaveDelay={0} placement="bottomLeft" overlay={(<div className='tips'><p>{intl.formatMessage({id: 'freeTrialTips'})}</p><span className="iconfont icona-16pt1 btn" onClick={()=>{setFreeTips(false)}}></span></div>)} overlayStyle={{width: 220}} >
                    //     <button onClick={()=>setStore({value: true, type: 'purchaseVisible'})} className={css.red}><i className='iconfont iconVIPp' /> {intl.formatMessage({id: 'freeTrial'})}</button>
                    //   </Tooltip>
                    //   ): null
                  }
                  
                  <button onClick={()=>setVedioVisible(true)} style={{width: 110, marginRight: 10}}><i className='iconfont iconcaozuoshuoming' /> {intl.formatMessage({id: 'helpVideos'})}</button>
                  
                  {/* <Link to="/my/purchase">
                    <i className="iconfont iconxufei"></i> Purchase
                  </Link>
                  <u>|</u>  */}
                  <Tooltip placement="bottom" overlay={user} overlayStyle={{width: 124}} overlayClassName="white">
                    <span>
                      <i className="iconfont iconwode"></i> {userInfo.data?.full_name || userInfo.data?.username} <i className="iconfont icondown"></i>
                    </span>
                  </Tooltip>
                  {/* <u>|</u> 
                  <span onClick={()=>setFeedbackVisible(true)}><i className="iconfont iconxieyi"></i> Feedback</span> */}
                  {/* {
                    userInfo.msg?.length ? (
                      <>
                        <u>|</u>
                        <Tooltip defaultVisible={true} placement="bottomRight" overlay={msg} overlayClassName="white">
                          <span className={css['msg']}>
                            <i className="iconfont iconxiaoxi3"></i>
                          </span>
                        </Tooltip>
                      </>
                    ): null
                  } */}
                  
                </>
              ): (
                <>
                  {/* <span>Location</span> */}
                  {/* <button onClick={()=>setStore({value: true, type: 'loginVisible'})} className={css.red}><i className='iconfont icona-16pt' /> {intl.formatMessage({id: 'invite'})}</button> */}
                  {/* <button onClick={()=>setStore({value: true, type: 'loginVisible'})} className={css.red}><i className='iconfont iconVIPp' /> {intl.formatMessage({id: 'freeTrial'})}</button> */}
                  <button onClick={()=>setVedioVisible(true)} style={{width: 110, marginRight: 10}}><i className='iconfont iconcaozuoshuoming' /> {intl.formatMessage({id: 'helpVideos'})}</button>
                  <button onClick={()=>setStore({value: true, type: 'loginVisible'})}>{intl.formatMessage({id: 'sign'})}</button>
                  <button onClick={()=>go('/register')} className={css.hollow}>{intl.formatMessage({id: 'register'})}</button>
                </>
              )
            }
          </div>
        </div>
      </div>
      {
        purchaseVisible ? <PurchaseModal /> : null
      }
      {
        feedbackVisible ? <Feedback closeFn={setFeedbackVisible} /> : null 
      }
      {/* <Modal
        title='Help Videos'
        visible={vedioVisible}
        cancel={()=>setVedioVisible(false)}
        width={900}
      >
        <div className={css.vedio}>
          <ul>
            {
              vedioData.map((item: any, index: number)=>{
                return (
                  <li className={index === vedioIndex ? css.active : ''} onClick={()=>setVedioIndex(index)}>{item.title}</li>
                )
              })
            }
          </ul>
          <video ref={videoRef} width={880} height={464} src={vedioData[vedioIndex]?.vedio_url} controls onTimeUpdate={()=>setVedioTime()}>
            Your browser does not support video。
          </video>
        </div>
      </Modal> */}
      <HelpVideo visible={vedioVisible} setVisible={setVedioVisible} activeIndex={vedioIndex} setActiveIndex={setVedioIndex} />
      {
        userInfo.userid ? <Invite visible={inviteVisible} setVisible={setInviteVisible} setNumberV={setNumberVisible} /> : null
      }
      
      {
        userInfo.data?.isinvited ? <Invited /> : null
      }
      {
        loginVisible ? <Login type="modal" /> : null
      }
      
      {
        buyVisible ? (
          <Modal
            title={intl.formatMessage({ id: 'purchase' })}
            visible={buyVisible}
            cancel={() => setBuyVisible(false)}
            width={880}
          >
            <div>
              <NewPurchase type="1"></NewPurchase>
            </div>
          </Modal>
        ): null
      }
    </div>
  )
}

export default Header