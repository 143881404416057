import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import MainContext from '@/context/MainContext'
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'
import Toast from '@/components/Toast'
import { PartSearch } from '@/page/Vin'
import vinApi from '@/api/vinApi'
import { getUrlParams, cdnsPath } from '@/utils/utils'
import css from './vin.module.scss'
import { useIntl } from 'react-intl'
import Guide from '@/components/Guide'


const Vin: React.FC = () => {
  const intl = useIntl()
  const guidecache = JSON.parse(localStorage.getItem('guidecache') || '{}')

  const urlParams = JSON.stringify(getUrlParams())
  const { store, setStore } = useContext(MainContext)
  const { crumb, userInfo, vinData } = store
  const history = useHistory()
  const navigate = (url: string) => history.push(url)
  
  const tempRef = useRef<any>({
    upkeep: true
  })
  const { vin, num, subindex, mcid, brandcode, samecrumb, vinmcid, name, vincode } = getUrlParams()
  const [ data, setData ] = useState<any>({})
  const [ group, setGroup ] = useState<any>([])
  const [ structure, setStructure ] = useState<any>([])
  const [ subGroup, setSubGroup ] = useState<any>([])
  const [ key, setKey ] = useState<string>('')
  const [ groupKey, setGroupKey ] = useState<string>('')

  const [ groupActive, setGroupActive ] = useState<number>(-1)
  const [ structureActive, setStructureActive ] = useState<number>(-1)
  const [ filter, setFilter ] = useState<boolean>(localStorage.getItem('filter') ? true : false)
  const [ structureShow, setStructureShow ] = useState<boolean>(localStorage.getItem('structureShow') ? true : false)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [ partKey, setPartKey ] = useState<string>('')
  const [ groupTotal, setGroupTotal ] = useState<any>({structure: 0, sub: 0})
  const [ subGroupShow, setSubGroupShow ] = useState<any>({menu: 'main', content: false})
  
  const [ configVisible, setConfigVisible ] = useState<boolean>(false)
  const [ config, setConfig ] = useState<any>([])

  const [ selectConfig, setSelectConfig ] = useState<any>({data: {}})
  const [ selectConfigVisible, setSelectConfigVisible ] = useState<boolean>(false)

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<number>(0)
  const [ progressVisible, setProgressVisible ] = useState<boolean>(false)

  const [ infoType, setInfoType ] = useState<string>('config')
  const [ upkeepLoading, setUpkeepLoading ] = useState<boolean>(false)
  
  
  let activeIndex = 0

  useEffect(()=>{
    if((vinData.vin && vinData.vin === vin) || (vinData.mcid && mcid && vinData.mcid === mcid.replace(/\=/g, '%3D'))){
      setData(vinData.data || {})
      setGroup(vinData.group || [])
      setStructure(vinData.structure || [])
      console.log(789)
      if(num){
        vinData.group.forEach((gitem: any, index: number)=>{
          if(gitem.num == num){
            setGroupActive(index)
          }
        })
      }
      if(subindex){
        
        const subindexArr = subindex.split(',')
        setSubGroup(vinData.subGroup || [])
        setSubGroupShow({
          menu: subindexArr.length === 1 ? 'structure' : subindexArr.length - 2,
          content: true
        })
      }
      
      setGroupTotal(vinData.groupTotal || {structure: 0, sub: 0})
      if(!vinmcid){
        const url = window.location.search + `&vinmcid=${vinData.mcid || vinData.data.mcid}&vincode=${vinData.brandcode || vinData.data.brandCode}`
        navigate(url)
      }
      return
    }
    if(vin){
      getVin(true, urlParams, vin, brandcode)
    }
    
    // catalog
    if(mcid && brandcode){
      getGroup({
        brandCode: brandcode,
        mcid: mcid.replace(/\=/g, '%3D')
      })
    }
  }, [])

  useEffect(()=>{
    if(configVisible){
      searchConfig()
    }
  }, [configVisible])

  useEffect(()=>{
    if(num && group.length){
      group.forEach((item: any, index: number)=>{
        if((num === '0' && !index) || item.num == num || item.tempNum == num){
          // if(!samecrumb){
          //   let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
          //   item.name = item.label
          //   item.type = 'group'
          //   tempCrumb.push(item)
          //   setStore({value: tempCrumb, type: 'crumb'})
          // }
          getStructure(index, true)
        }
      })
    }
  }, [group])

  useEffect(()=>{
    if(subindex && structure.length){
      getSubGroup(subindex.split(',')[0], true)
    }
  }, [structure])

  useEffect(()=>{
    if(progress && vin){
      getVin(false, urlParams, vin)
    }
  }, [progress])

  useEffect(()=>{
    if(infoType === 'upkeep' && tempRef.current.upkeep){
      getUpkeep()
    }
  }, [infoType])
  

  const getVin = (loading: boolean, urlParamsOld: string, pVin: string, brandcode?: string, mcid?: string) => {
    const urlParamsTemp = JSON.stringify(getUrlParams()) || null
    if(urlParamsOld !== urlParamsTemp){
      return
    }
    let param: any = {
      vin: pVin
    }
    if(brandcode){
      param.brandCode = brandcode
    }
    if(mcid){
      param.mcid = mcid
    }
    setSelectConfig({data: {}})
    setSelectConfigVisible(false)
    if(loading){
      setLoading(true)
    }
    // axios.all([vinApi.vin(param), vinApi.upkeep({vin: pVin})]).then(axios.spread((result, upkeepResult) => {
    vinApi.vin(param).then((result: any) => {
      const urlParamsTemp = JSON.stringify(getUrlParams()) || null
      if(urlParamsOld !== urlParamsTemp){
        return
      }
      if(result.code === 1 || result.code === 10) {
        let vinTemp = result.data.vin || vin
        let tempCrumb: any = [
          {
            type: 'logo',
            img: `${cdnsPath}/img/${result.data.brandCode || result.brandCode}.png`
          },
          {
            name: vinTemp.length > 20 ? vin : vinTemp
          }
        ]
        
        result.title?.map((name: string)=>{
          tempCrumb.push({
            name,
            type: 'config'
          })
        })
        if (result.data && result.data.maindata) {
          result.data.config_list = []
          if (!result.data.mainkeys) {
            result.data.config_list = Object.keys(data.data.maindata);
          }
          result.data.mainkeys.forEach((item:any) => {
            result.data.config_list.push({
              title: item,
              value: result.data.maindata[item],
            })
          })
        }
        result.data.upkeep = {}

        window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
        if(!samecrumb){
          setStore({value: tempCrumb, type: 'crumb'})
        }
        let vinDataTemp = {
          ...param,
          data: result
        }
        
        if(vinTemp.length > 20 || vin.length < 10){
          navigate(`/vin?vin=${vinTemp}`)
        }
        
        if(name){
          setPartKey(name)
          setPartSearchkey(1)
          navigate(`/vin?vin=${vinTemp}`)
        }
        if(!vinmcid){
          const url = window.location.search + `&vinmcid=${result.data.mcid}&vincode=${result.data.brandCode || result.brandCode}`
          navigate(url)
        }
        setData(result)
        getGroup({
          vin: vinTemp,
          brandCode: result.data.brandCode || result.brandCode,
          mcid: result.data.mcid
        }, vinDataTemp)
      }else if(result.code !== 4001) {
        setLoading(false)
      }
      if(result.code === 4005) {
        let list: any = []
        let columns: any = []
        if(result.select_data){
          result.title.forEach((item: any)=>{
            columns.push({
              title: item,
              dataIndex: item
            })
          })
          result.select_data.forEach((item: any, index: number)=>{
            let obj: any = {}
            item.contentData.forEach((content: any, i: number)=>{
              obj[result.title[i]] = content
            })
            obj.mcid = item.mcid
            obj.title = result.title
            obj.key = index
            if(item.vehicleid){
              obj.vehicleid = item.vehicleid
            }
            list.push(obj)
          })
        }else {
          result.data.mcid.title.forEach((item: any)=>{
            columns.push({
              title: item,
              dataIndex: item
            })
          })
          result.data.mcid.data.forEach((item: any, index: number)=>{
            let obj: any = {}
            item.contentData.forEach((content: any, i: number)=>{
              obj[result.data.mcid.title[i]] = content
            })
            obj.mcid = item.mcid
            obj.vin = result.data.vin
            obj.brandCode = result.data.brandCode
            obj.title = result.title
            obj.key = index
            list.push(obj)
          })
        }

        result.columns = columns
        result.list = list

        let sc = {
          ...param,
          data: result
        }
        setSelectConfig(sc)
        setSelectConfigVisible(true)
      }
      if(result.code === 4003){
        let columns = [
          {
            title: '图片',
            dataIndex: 'full_imglogo',
            type: 'img'
          },
          {
            title: 'vin',
            dataIndex: 'vehicleid'
          },
          {
            title: '名称',
            dataIndex: 'name'
          },
          {
            title: '年份',
            dataIndex: 'year'
          }
        ]
        
        result.data.forEach((item: any)=>{
          item.vehicleid = item.vin
          delete item.vin
        })
        result.columns = columns
        result.list = result.data
        let sc = {
          ...param,
          data: result
        }
        setSelectConfig(sc)
        setSelectConfigVisible(true)
      }
      if(result.code === 4001) {
        if(progress){
          if(progress < 3){
            setTimeout(()=>{
              setProgress(progress + 1)
            }, 3000)
          }else {
            // setProgressVisible(false)
            setLoading(false)
            setProgress(0)
            Toast(result.msg, 'warning')
          }
        }else {
          // setProgressVisible(true)
          setTimeout(()=>{
            setProgress(1)
          }, 3000)
        }
      }
    })
    // vinApi.vin(param).then((result: any)=>{
      
    // })
  }
  const getGroup = (param: object, vd?: {}) => {
    if(name){
      setPartKey(name)
      setPartSearchkey(1)
    }
    vinApi.group(param).then((result: {code: number, data: any})=>{
      setLoading(false)
      if(result.code === 1) {
        result.data.forEach((item: any)=>{
          if(item.group_type === 'maintenance'){
            item.tempNum = '8888'
          }
        })
        if(result.data[num || 0]?.click === 0){
          result.data.forEach((item: any)=>{
            if(item.click === 1){
              const url = window.location.search.replace(/&num=\d/, '&num=' + item.num)
              navigate(url)
            }
          })
        }
        let vinDataTemp = vd || JSON.parse(JSON.stringify(vinData))
        if(!vd && !vin && mcid){
          vinDataTemp.mcid = mcid.replace(/\=/g, '%3D')
          vinDataTemp.brandcode = brandcode
        }
        vinDataTemp.group = result.data
        sessionStorage.setItem('vinData', JSON.stringify(vinDataTemp))
        setStore({value: vinDataTemp, type: 'vinData'})
        setGroup(result.data)
      }
    })
  }
  const getStructure = (index: number, jump?: boolean) => {
    if(groupActive === index){
      return
    }
    if(group[index].click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }

    if(jump && (vinData.vin || vinData.mcid) && vinData.structure){
      return
    }

    activeIndex = index
    setGroupActive(index)
    setSubGroupShow({
      menu: 'main',
      content: false
    })
    setStructureActive(-1)

    const item = group[index]
    const param: any = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num
    }
    let api: any = {}
    if(item.group_type){
      param.group_type = item.group_type
      api = vinApi.subGroup(param)
    }else {
      param.num = item.num
      api = vinApi.structure(param)
    }
    setStructure([])
    setSubGroup([])
    api.then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === index) {
        let total = 0
        result.data.forEach((d: any)=>{
          if(item.group_type){
            d.img = d.img_url
            d.mcid = item.mcid
            d.brandCode = item.brandCode
            d.num = item.tempNum
          }else {
            // d.img = d.structure_illustration ? result.imgs[d.structure_illustration] || '' : result.imgs[d.imageLarge] || ''
            d.img = result.imgs[d.structure_illustration] || result.imgs[d.imageLarge] || ''
          }
          
          
          // catalog
          if((mcid && brandcode) || item.group_type){
            d.colorvalue = 1
          }

          if(d.colorvalue){
            total++
          }
        })
        groupTotal.structure = total

        let vinDataTemp = JSON.parse(JSON.stringify(vinData))
        vinDataTemp.structure = result.data
        vinDataTemp.groupTotal = groupTotal
        setStore({value: vinDataTemp, type: 'vinData'})

        setGroupTotal(groupTotal)
        setStructure(result.data)
      }
    })
    if(!jump){
      let url = `/vin?vin=${vin}&brandcode=${brandcode || vincode}&num=${item.num || item.tempNum}`
      //catalog
      if(mcid && brandcode){
        url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode || vincode}&num=${item.num}`
      }
      
      item.name = item.label
      item.type = 'group'
      let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
      
      const tempCrumbPop = tempCrumb[tempCrumb.length - 1]
      if(tempCrumbPop.type !== 'config' && tempCrumbPop.level === item.level){
        tempCrumb.pop()
      }
      tempCrumb.push(item)
      setStore({value: tempCrumb, type: 'crumb'})
      
      navigate(url)
    }else if(samecrumb){
      const url = window.location.search.replace('&samecrumb=true', '')
      navigate(url)
    }
  }

  const getSubGroup = (i: number, jump?: boolean, type?: any) => {
    let menuIndex: any = null
    let tempSub = JSON.parse(JSON.stringify(subGroup))
    if((type || type === 0) && type !== 'structure'){
      menuIndex = type + 1
    }
    // if(structureActive === i && type === 'structure'){
    //   return
    // }
    if(type === 'structure' && structure[i].click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }
    if(jump && (vinData.vin || vinData.mcid) && vinData.structure){
      return
    }
    activeIndex = i
    setStructureActive(i)
    setSubGroupShow({
      menu: type || type === 0 ? type : subGroup.length - 1,
      content: true
    })
    console.log(subGroup)
    let item: any = {}
    if(type === 'structure'){
      item = structure[i]
    }else if(menuIndex) {
      item = subGroup[type][i]
    }else {
      item = subGroup[subGroup.length - 1][i]
    }
    const param = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num,
      subgroup: item.mid,
      maingroup: item.maingroup,
      level: item.level
    }
    
    if(item.level === 1){
      setSubGroup([[]])
    }else if(menuIndex){
      tempSub.length = menuIndex
      groupTotal.sub.length = menuIndex
      tempSub.push([])
      groupTotal.sub.push(0)
      setGroupTotal(groupTotal)
      setSubGroup(tempSub)
    }
    
    let tempIndex: any = [i]
    if(subindex){
      tempIndex = subindex.split(',')
      if(menuIndex){
        tempIndex.length = menuIndex
      }
      if(type === 'structure') {
        tempIndex = []
      }
      tempIndex.push(i)

    }
    tempIndex = tempIndex.join(',')
    let url = `/vin?vin=${vin}&brandcode=${brandcode}&num=${item.num}&subindex=${tempIndex}`
    //catalog
    if(mcid && brandcode){
      url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&subindex=${tempIndex}`
    }
    if(crumb[crumb.length - 1].level === item.level){
      crumb.pop()
    }
    
    item.type = 'subGroup'
    crumb.push(item)


    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === i) {
        let vinDataTemp = JSON.parse(JSON.stringify(vinData))
        let total = 0
        result.data.forEach((d: any)=>{
          d.img = d.structure_illustration ? result.imgs[d.structure_illustration] || '' : result.imgs[d.imageLarge] || ''
          // catalog
          if(mcid && brandcode){
            d.colorvalue = 1
          }
          if(d.colorvalue){
            total++
          }
        })
        
        if(item.level === 1){
          groupTotal.sub = [total]
          setSubGroup([result.data])
          vinDataTemp.subGroup = [result.data]
        }else {
          if(menuIndex){
            tempSub.length = menuIndex
            groupTotal.sub.length = menuIndex
          }
          tempSub.push(result.data)
          groupTotal.sub.push(total)
          
          setSubGroup(tempSub)
          vinDataTemp.subGroup = tempSub
        }
        setGroupTotal(groupTotal)
        vinDataTemp.groupTotal = groupTotal
        setStore({value: vinDataTemp, type: 'vinData'})
        sessionStorage.setItem('vinData', JSON.stringify(vinDataTemp))
        if(!type && type !== 0){
          setStore({value: crumb, type: 'crumb'})
          navigate(url)
        }
      }
    })
    
    
    if(type || type === 0){
      setStore({value: crumb, type: 'crumb'})
      navigate(url)
    }
    
  }
  const goDetail = (i: number) => {
    let item: any = structure[i]
    let param = ''
    if(subindex){
      item = subGroup[subGroup.length - 1][i]
      item.index = i
      sessionStorage.setItem('subGroup', JSON.stringify(subGroup))
    }else {
      sessionStorage.removeItem('subGroup')
    }
    item.type = 'detail'
    crumb.push(item)
    setStore({value: crumb, type: 'crumb'})

    param = `vin=${vin || ''}&brandcode=${item.brandCode}&mcid=${item.mcid}&num=${item.num || item.tempNum}&index=${i}`
    if(subindex){
      param += `&subindex=${subindex}`
    }
    sessionStorage.setItem('structure', JSON.stringify(structure))
    
    // if(subindex || subindex === 0){
    //   const subItem = subGroup[subGroup.length - 1][subindex]
    //   param = `vin=${vin || ''}&brandcode=${subItem.brandCode}&mcid=${subItem.mcid}&num=${subItem.num}&index=${i}&subindex=${subindex}`
    //   sessionStorage.setItem('subGroup', JSON.stringify(subGroup))
    //   subItem.type = 'detail'
    //   subItem.index = i
    //   crumb.push(subItem)
    //   setStore({value: crumb, type: 'crumb'})
    // }else {
      
    //   setStore({value: crumb, type: 'crumb'})
    //   sessionStorage.removeItem('subGroup')
    // }
    if(item.final_id){
      param += `&finalid=${item.final_id}`
    }
    let url = `/vin/detail?${param}`
    //catalog
    if(mcid && brandcode){
      url = `/group/detail?${param}`
    }

    let tempCrumb = window.sessionStorage.getItem('crumb') || '[]'
    // window.sessionStorage.setItem('groupCrumb', tempCrumb)
    window.sessionStorage.setItem('crumb', JSON.stringify(crumb))
    navigate(url)
  }
  const setFilterLS = () => {
    let val = filter ? false : true
    setFilter(val)
    if(val){
      localStorage.setItem('filter', '1')
    }else {
      localStorage.removeItem('filter')
    }
  }
  const setStructureShowLS = () => {
    let val = structureShow ? false : true
    setStructureShow(val)
    if(val){
      localStorage.setItem('structureShow', '1')
    }else {
      localStorage.removeItem('structureShow')
    }
  }
  const setSubGroupShowFn = (type: any) => {
    let temp = JSON.parse(JSON.stringify(subGroupShow))
    temp.menu = type
    setSubGroupShow(temp)
  }

  const searchConfig = (key_words?: string) => {
    if(key_words){
      vinApi.config({vin, brandCode: brandcode || vincode, key_words}).then((result: {code: number, data: any})=>{
        if(result.code === 1){
          setConfig(result.data.confs)
        }else {
          setConfig([])
        }
      })
    }else {
      setConfig([])
    }
  }

  const back = () => {
    crumb.splice(crumb.length - 1, 1)
    setStore({value: crumb, type: 'crumb'})
    sessionStorage.setItem('crumb', JSON.stringify(crumb))
    if(!subindex){
      if(mcid && brandcode){
        navigate('/search')
      }else {
        setGroupActive(-1)
      }
    }else {
      let subindexArr = subindex.split(',')
      let subGroupTemp = JSON.parse(JSON.stringify(subGroup))
      let groupTotalTemp = JSON.parse(JSON.stringify(groupTotal))
      let url = `/vin?vin=${vin}&num=${num}&brandcode=${brandcode || vincode}`
      subGroupTemp.pop()
      groupTotalTemp.sub.pop()
      setSubGroup(subGroupTemp)
      setGroupTotal(groupTotalTemp)

      //catalog
      if(mcid && brandcode){
        url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode || vincode}&num=${num}`
      }
      if(subindexArr.length > 1){
        const activeMenu = subindexArr.pop()
        setSubGroupShow({
          menu: subindexArr.length === 1 ? 'structure' : activeMenu,
          content: true
        })
        url += `&subindex=${subindexArr.join(',')}`
      }else {
        if(num){
          group.forEach((gitem: any, index: number)=>{
            if(gitem.num == num){
              setGroupActive(index)
            }
          })
        }
        setSubGroupShow({
          menu: 'main',
          content: false
        })
      }
      
      navigate(url)
    }
    
  }
  const clickConfig = (item: any) => {
    if(!item.vin){
      getVin(true, urlParams, item.vehicleid || vin, selectConfig.data.brandCode, item.mcid)
    }else {
      const result = selectConfig.data
      setSelectConfigVisible(false)

      let tempCrumb: any = [
        {
          type: 'logo',
          img: `${cdnsPath}/img/${result.brandCode}.png`
        },
        {
          name: vin
        }
      ]
      
      result.title?.map((name: string)=>{
        tempCrumb.push({
          name,
          type: 'config'
        })
      })
      if (result.data && result.data.maindata) {
        result.data.config_list = []
        if (!result.data.mainkeys) {
          result.data.config_list = Object.keys(data.data.maindata);
        }
        result.data.mainkeys.forEach((item:any) => {
          result.data.config_list.push({
            title: item,
            value: result.data.maindata[item],
          })
        })
      }
      window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
      if(!samecrumb){
        setStore({value: tempCrumb, type: 'crumb'})
      }
      let vinDataTemp = JSON.parse(JSON.stringify(selectConfig))

      if(name){
        setPartKey(name)
        setPartSearchkey(1)
        navigate(`/vin?vin=${vin}`)
      }

      setData(result)
      getGroup({
        vin,
        brandCode: result.brandCode,
        mcid: item.mcid
      }, vinDataTemp)
      if(!vinmcid){
        const url = window.location.search + `&vinmcid=${item.mcid}&vincode=${result.brandCode}`
        navigate(url)
      }
    }
  }
  const getUpkeep = () => {
    tempRef.current.upkeep = false
    setUpkeepLoading(true)
    vinApi.upkeep({vin}).then((upkeepResult: any) => {
      setUpkeepLoading(false)
      if(upkeepResult.code === 1){
        let temp = JSON.parse(JSON.stringify(data))
        if(upkeepResult.data.MaintInfo){
          const maintInfo = upkeepResult.data.MaintInfo
          let oil = []
          let air = []
          let pollen = []
          let fuel = []
          let spark = []
          if(maintInfo.OilFilterReplaceIntervalsKm){
            oil.push(`${maintInfo.OilFilterReplaceIntervalsKm}KM`)
          }
          if(maintInfo.OilFilterReplaceIntervalsMonth){
            oil.push(`${maintInfo.OilFilterReplaceIntervalsMonth}个月`)
          }
          if(oil.length){
            oil.push('次')
            upkeepResult.data.MaintInfo.oil = oil.join('/')
          }

          if(maintInfo.AirCleanerElementReplaceIntervalsKm){
            air.push(`${maintInfo.AirCleanerElementReplaceIntervalsKm}KM`)
          }
          if(maintInfo.AirCleanerElementReplaceIntervalsMonth){
            air.push(`${maintInfo.AirCleanerElementReplaceIntervalsMonth}个月`)
          }
          if(air.length){
            air.push('次')
            upkeepResult.data.MaintInfo.air = air.join('/')
          }

          if(maintInfo.PollenFilterReplaceIntervalsKm){
            pollen.push(`${maintInfo.PollenFilterReplaceIntervalsKm}KM`)
          }
          if(maintInfo.PollenFilterReplaceIntervalsMonth){
            pollen.push(`${maintInfo.PollenFilterReplaceIntervalsMonth}个月`)
          }
          if(pollen.length){
            pollen.push('次')
            upkeepResult.data.MaintInfo.pollen = pollen.join('/')
          }

          if(maintInfo.FuelFilterReplaceIntervalsKm){
            fuel.push(`${maintInfo.FuelFilterReplaceIntervalsKm}KM`)
          }
          if(maintInfo.FuelFilterReplaceIntervalsMonth){
            fuel.push(`${maintInfo.FuelFilterReplaceIntervalsMonth}个月`)
          }
          if(fuel.length){
            fuel.push('次')
            upkeepResult.data.MaintInfo.fuel = fuel.join('/')
          }

          if(maintInfo.SparkPlugsReplaceIntervalsKm){
            spark.push(`${maintInfo.SparkPlugsReplaceIntervalsKm}KM`)
          }
          if(maintInfo.SparkPlugsReplaceIntervalsMonth){
            spark.push(`${maintInfo.SparkPlugsReplaceIntervalsMonth}个月`)
          }
          if(spark.length){
            spark.push('次')
            upkeepResult.data.MaintInfo.spark = spark.join('/')
          }
        }
        temp.data.upkeep = upkeepResult.data
        setData(temp)
      }
    })
  }
  return (
    <>
      {
        vin || (mcid && brandcode) ? (
          <Loading visible={loading}>
            <div className={css['vin']}>
              <div className={css['l']}>
                <div className={css['box']}>
                  <div className={css['box-top']} onClick={()=>setSubGroupShowFn('main')}>
                    <h2>{intl.formatMessage({id: 'mainGroup'})}({group.length})</h2>
                    <div className={css['box-top-tools']}>
                      <span>
                        {/* <input value={key} type="text" placeholder="Maingroup Search" onChange={(e)=>setKey(e.target.value)} /> */}
                      </span>
                    </div>
                  </div>
                  {
                    subGroupShow.menu === 'main' ? (
                      <div className={css['box-content']} style={{height: `calc(100vh - ${200 + 46 * (structure.length ? subGroup.length : (subGroup.length ? subGroup.length + 1 : 0))}px)`}}>
                        <ul className={css['group']}>
                          {
                            group.map((item: {label: string, img: string, num: string, click?: number}, index: number) => {
                              return (
                                !key || item.label.indexOf(key) !== -1 || item.num.indexOf(key) !== -1 ? (
                                  <li key={index} className={groupActive === index ? css['active'] +' '+ css['small'] : css['small']} onClick={()=>getStructure(index)}>
                                    <img src={item.img} />
                                    <p>{item.num} {item.label}</p>
                                    {
                                      item.click === 0 ? <div></div> : null
                                    }
                                  </li>
                                  // <li key={index} className={groupActive === index ? css['active'] +' '+ css['small'] : css['small']} onClick={()=>getStructure(index)}>
                                  //   <span>{item.num}</span>
                                  //   <p>{item.label}</p>
                                  //   <img src={item.img} />
                                  //   {
                                  //     item.click === 0 ? <div></div> : null
                                  //   }
                                  // </li>
                                ): null
                              )
                            })
                          }
                        </ul>
                      </div>
                    ): null
                  }
                  {
                    subGroupShow.content ? (
                      <>
                        <div className={css['box-top']} onClick={()=>setSubGroupShowFn('structure')}>
                          <h2>{intl.formatMessage({id: 'group'})}({structure.length})</h2>
                          <div className={css['box-top-tools']}>
                            <span>
                              {/* <input value={groupKey} type="text" placeholder="Group Search" onChange={(e)=>setGroupKey(e.target.value)} /> */}
                              {/* <i className="iconfont iconsousuo" /> */}
                            </span>
                          </div>
                        </div>
                        {
                          subGroupShow.menu === 'structure' ? (
                            <div className={css['box-content']} style={{height: `calc(100vh - ${200 + 46 * (structure.length ? subGroup.length : (subGroup.length ? subGroup.length + 1 : 0))}px)`}}>
                              <ul className={css['group']}>
                                {
                                  structure.map((item: {name: string, img: string, mid: string, click?: number}, i: number) => {
                                    return (
                                      !groupKey || item.name.indexOf(groupKey) !== -1 || item.mid.indexOf(groupKey) !== -1 ? (
                                        <li key={i} className={subindex && (subindex.split(',')[0] == i) ? css['active'] : ''} onClick={()=>getSubGroup(i, false, 'structure')}>
                                          <img src={item.img} />
                                          <p>{item.mid} {item.name}</p>
                                          {
                                            item.click === 0 ? <div></div> : null
                                          }
                                        </li>
                                        // <li key={index} className={structureActive === index ? css['active'] : ''} onClick={()=>getSubGroup(index)}>
                                        //   <span>{item.mid}</span>
                                        //   <p>{item.name}</p>
                                        //   <img src={item.img} />
                                        //   {
                                        //     item.click === 0 ? <div></div> : null
                                        //   }
                                        // </li>
                                      ): null
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          ): null
                        }
                      </>
                    ): null
                  }

                  {
                    subGroup.map((groupItem: any, groupIndex: number)=>{
                      return (
                        subGroup.length - 1 > groupIndex ? (
                          <>
                            <div key={groupIndex} className={css['box-top']} onClick={()=>setSubGroupShowFn(groupIndex)}>
                              <h2>{intl.formatMessage({id: 'group'})}({groupItem.length})</h2>
                              <div className={css['box-top-tools']}>
                                <span>
                                  {/* <input value={groupKey} type="text" placeholder="Group Search" onChange={(e)=>setGroupKey(e.target.value)} /> */}
                                  {/* <i className="iconfont iconsousuo" /> */}
                                </span>
                              </div>
                            </div>
                            {
                              subGroupShow.menu == groupIndex ? (
                                <div className={css['box-content']} style={{height: `calc(100vh - ${200 + 46 * (structure.length ? subGroup.length : subGroup.length + 1)}px)`}}>
                                  <ul className={css['group']}>
                                    {
                                      groupItem.map((item: {name: string, img: string, mid: string, click?: number}, i: number) => {
                                        return (
                                          !groupKey || item.name.indexOf(groupKey) !== -1 || item.mid.indexOf(groupKey) !== -1 ? (
                                            <li key={i} className={subindex && (subindex.split(',')[groupIndex + 1] == i) ? css['active'] : ''} onClick={()=>getSubGroup(i, false, groupIndex)}>
                                              <img src={item.img} />
                                              <p>{item.mid} {item.name}</p>
                                              {
                                                item.click === 0 ? <div></div> : null
                                              }
                                            </li>
                                            // <li key={index} className={structureActive === index ? css['active'] : ''} onClick={()=>getSubGroup(index)}>
                                            //   <span>{item.mid}</span>
                                            //   <p>{item.name}</p>
                                            //   <img src={item.img} />
                                            //   {
                                            //     item.click === 0 ? <div></div> : null
                                            //   }
                                            // </li>
                                          ): null
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              ): null
                            }
                          </>
                        ): null
                      )
                    })
                  }

                </div>
              </div>

              {
                groupActive === -1 && !subindex ? (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        <div className={css['box-h']}>
                          <h2>{intl.formatMessage({id: 'configDetails'})}</h2>

                          {/* <h2 className={infoType === 'config' ? css.active : ''} onClick={()=>setInfoType('config')}>配置详情</h2> */}
                          {/* {
                            data.data && data.data.upkeep ? (
                              <h2 className={infoType === 'upkeep' ? css.active : ''} onClick={()=>setInfoType('upkeep')}>养护油品</h2>
                            ): null
                          } */}
                        </div>
                        <div className={css['box-top-tools']}>
                          <div style={{marginLeft: 10}}>
                            {/* <SearchPartInput 
                              mcid={mcid?.replace(/\=/g, '%3D') || (data.data && data.data.mcid) }
                              brandcode={brandcode || data.brandCode}
                              vin={vin || ''}  
                            /> */}
                          </div>
                          
                          {/* <span>
                            <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}
                        </div>
                      </div>
                      <div className={css['box-content']}>
                        {
                          infoType === 'config' ? (
                            <div className={css['detail']}>
                              {
                                data.data && data.data.config_list && data.data.config_list.map((item: {title: string, value: string}, index: number)=>{
                                  return (
                                    <p key={index}>
                                      <label>{item.title}：</label>
                                      <span>{item.value}</span>
                                    </p>
                                  )
                                })
                              }
                              
                            </div>
                          ): null
                        }
                        {
                          infoType === 'upkeep' ? (
                            <Loading visible={upkeepLoading}>
                              <div className={css['detail']}>
                                {
                                  data.data.upkeep.CarInfo ? (
                                    <div className={css.upkeep}>
                                      <h5>原厂机油</h5>
                                      <ul>
                                        {
                                          data.data.upkeep.CarInfo.JiChuYouLeiXing ? (
                                            <p><label>类型:</label><span>{data.data.upkeep.CarInfo.JiChuYouLeiXing}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.CarInfo.Spec ? (
                                            <p><label>粘度:</label><span>{data.data.upkeep.CarInfo.Spec}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.CarInfo.OilSpecs ? (
                                            <p><label>机油标注:</label><span>{data.data.upkeep.CarInfo.OilSpecs}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.CarInfo?.MaintenanceOils ? (
                                            <p><label>推荐加注量:</label><span>{data.data.upkeep.CarInfo.MaintenanceOils}L</span></p>
                                          ): null
                                        }
                                        {/* {
                                          data.data.upkeep.CarInfo?.ImagePath ? (
                                            <p><label>图片:</label><span><img src={data.data.upkeep.CarInfo?.ImagePath} height={40} onClick={()=>this.setState({imgUrl: data.data.upkeep.CarInfo?.ImagePath})} /></span></p>
                                          ): null
                                        } */}
                                      </ul>
                                    </div>
                                  ): null
                                }
                                {
                                  data.data.upkeep.GearBoxOil ? (
                                    <div className={css.upkeep}>
                                      <h5>变速箱</h5>
                                      <ul>
                                        {
                                          data.data.upkeep.McidInfo?.transmission_description ? (
                                            <p><label>变速箱/档位:</label><span>{data.data.upkeep.McidInfo?.transmission_description}/{data.data.upkeep.CarInfo?.GearCount}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.GearBoxOil.BaoYangJiaZhuLiang ? (
                                            <p><label>换油量:</label><span>重力:{data.data.upkeep.GearBoxOil.BaoYangJiaZhuLiang}; 机器:{data.data.upkeep.GearBoxOil.XunHuanJiaZhuLiang}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.GearBoxOil.DaXiuJiaZhuLiang ? (
                                            <p><label>大修加注量:</label><span>{data.data.upkeep.GearBoxOil.DaXiuJiaZhuLiang}</span></p>
                                          ): null
                                        }
                                      </ul>
                                    </div>
                                  ): null
                                }
                                {
                                  data.data.upkeep.MaintInfo ? (
                                    <div className={css.upkeep}>
                                      <h5>养护信息</h5>
                                      <ul>
                                        {
                                          data.data.upkeep.MaintInfo.oil ? (
                                            <p><label>机油过滤器:</label><span>{data.data.upkeep.MaintInfo.oil}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.air ? (
                                            <p><label>空气过滤器:</label><span>{data.data.upkeep.MaintInfo.air}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.pollen ? (
                                            <p><label>空调过滤器:</label><span>{data.data.upkeep.MaintInfo.pollen}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.fuel ? (
                                            <p><label>燃油滤清器:</label><span>{data.data.upkeep.MaintInfo.fuel}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.spark ? (
                                            <p><label>火花塞:</label><span>{data.data.upkeep.MaintInfo.spark}</span></p>
                                          ): null
                                        }
                                      </ul>
                                    </div>
                                  ): null
                                }
                                
                              </div>
                            </Loading>
                          ): null
                        }
                      </div>
                    </div>
                    {
                      data.data && data.data.full_imglogo ? (
                        <img className={css['model-img']} alt="icon" src={data.data.full_imglogo} />
                      ): null
                    }
                  </div>
                ): (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        {
                          subGroupShow.content ? (
                            <h2>{intl.formatMessage({id: 'selectGroup'})}({ filter ? subGroup.length : groupTotal.sub[groupTotal.sub.length - 1] })</h2>
                          ): (
                            <h2>{intl.formatMessage({id: 'selectGroup'})}({ filter ? structure.length : groupTotal.structure })</h2>
                          )
                        }
                        
                        <div className={css['box-top-tools']}>
                          <button onClick={()=>back()} className={css['other']}><i className="iconfont iconleft" />{intl.formatMessage({ id: 'back' })}</button>
                          <button className={css['other']} onClick={()=>setStructureShowLS()}><i className={structureShow ? "iconfont iconqiehuantupian" : "iconfont iconliebiao1"} style={{color: '#EBBC00'}} />{structureShow ? intl.formatMessage({ id: 'image' }) : intl.formatMessage({ id: 'list' })}</button>
                          {
                            vin ? (
                              <>
                                <button className={css['other']} onClick={()=>setConfigVisible(true)}><i className="iconfont icon-peizhi" style={{color: '#1890FF'}} /><i className="iconfont iconpeizhi" style={{color: '#1890FF'}} />{intl.formatMessage({ id: 'config' })}</button>
                                <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont iconyiguolv" style={{color: '#616BF1'}} />{filter ? intl.formatMessage({id: 'notFilter'}) : intl.formatMessage({id: 'filter'})}</button>
                              </>
                            ): null
                          }
                          
                          {/* <div style={{marginLeft: 10}}>
                            <SearchPartInput 
                              mcid={mcid?.replace(/\=/g, '%3D') || (data.data && data.data.mcid) }
                              brandcode={brandcode || data.brandCode}
                              vin={vin || ''}  
                            />
                          </div> */}
                          {/* <span>
                            <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}
                        </div>
                      </div>
                      <div className={css['box-content']}>
                        <div className={css['detail']}>
                          {
                            structureShow ? (
                              <>
                                <table className={css['table-header']}>
                                  <thead>
                                    <tr>
                                      <th style={{width: '10%'}}>{intl.formatMessage({id: 'picture'})}</th>
                                      <th style={{width: '5%'}}>{intl.formatMessage({id: 'mg'})}</th>
                                      <th style={{width: '5%'}}>{intl.formatMessage({id: 'sg'})}</th>
                                      <th style={{width: '10%'}}>{intl.formatMessage({id: 'illNo'})}</th>
                                      <th style={{width: '15%'}}>{intl.formatMessage({id: 'name'})}</th>
                                      <th style={{width: '15%'}}>{intl.formatMessage({id: 'remark'})}</th>
                                      <th style={{width: '40%'}}>{intl.formatMessage({id: 'model'})}</th>
                                    </tr>
                                  </thead>
                                </table>
                                <div className={css['list']}>
                                  <table>
                                    <tbody>
                                    {
                                      subGroupShow.content ? (
                                        subGroup[subGroup.length - 1].map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? (css.grey + ' ' + css['filter']) : css['filter'])} onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}}>
                                                <td style={{width: '10%'}}><img src={item.img} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name || item.standard_subgroup_name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      ): (
                                        structure.map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? css.grey : css['filter'])} onClick={()=>{if(item.has_child){getSubGroup(index, false, 'structure')}else {goDetail(index)}}}>
                                                <td style={{width: '10%'}}><img src={item.img} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name || item.standard_subgroup_name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      )
                                    }
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ): (
                              <ul className={css['group']}>
                                {
                                  subGroupShow.content ? (
                                    subGroup[subGroup.length - 1].map((item: any, index: number)=>{
                                      return (
                                        filter || item.colorvalue ? (
                                          <li key={index} onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}} className={item.colorvalue ? '' : css['filter']}>
                                            <img src={item.img} />
                                            <p>{item.mid}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                        ): null
                                      )
                                    })
                                  ): (
                                    structure.map((item: any, index: number)=>{
                                      return (
                                        filter || item.colorvalue ? (
                                          <li key={index}  onClick={()=>{if(item.has_child){getSubGroup(index, false, 'structure')}else {goDetail(index)}}} className={item.colorvalue ? '' : css['filter']}>
                                            <span className={css.name}>{item.name}</span>
                                            <img src={item.img} />
                                            <p>{brandcode === 'benz' ? item.subgroup : (item.mid || item.standard_subgroup_name)}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                        ): null
                                      )
                                    })
                                  )
                                }
                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </Loading>
        ): null
      }
      
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partKey}
            mcid={mcid?.replace(/\=/g, '%3D') || data.data.mcid }
            brandcode={brandcode || data.brandCode}
            vin={vin || ''}
          />
        ): null
      }
      <Modal
        title={intl.formatMessage({id: 'config'})}
        visible={configVisible}
        cancel={setConfigVisible}
      >
        <div className={css['config']}>
          <div className={css['search']}>
            <input placeholder="Please input key" onChange={(e)=>searchConfig(e.target.value)} />
          </div>
          <div className={css['content']}>
            {
              config.map((item: {title: string, value: string}, index: number)=>{
                return (
                  <div className={css['item']} key={index}>
                    <label>{item.title}</label>
                    <span>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>
      <Modal
        title={selectConfig.desc || intl.formatMessage({id: 'selectConfigurationInformation'})}
        visible={selectConfigVisible}
        cancel={setSelectConfigVisible}
        width={1000}
      >
        <div className={css['select-config']}>
          <div className={css['select-brand']}>
            <span>{selectConfig.data.res_desc}</span>
            <img src={`${cdnsPath}/img/${selectConfig.data.brandCode}.png`} />
          </div>
          <table>
            <thead>
              <tr>
                {
                  selectConfig.data.columns && selectConfig.data.columns.map((item: any, index: number)=>{
                    return (
                      <th key={index}>{item.title}</th>
                    )
                  })
                }
              </tr>
            </thead>
          </table>
          <div className={css['select-scroll']}>
            <table>
              <tbody>
                {
                  selectConfig.data.list && selectConfig.data.list.map((item: any, index: number)=>{
                    return (
                      <tr onClick={()=>clickConfig(item)} key={index}>
                        {
                          selectConfig.data.columns.map((title: any, i: number)=>{
                            return (
                              <td key={i}>
                                {
                                  title.type === 'img' ? (
                                    <img src={item[title.dataIndex]} style={{height: 80}} />
                                  ): (
                                    <>{item[title.dataIndex]}</>
                                  )
                                }
                                
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      {
        userInfo.userid && !guidecache.group ? <Guide type='group' /> : null
      }
      {/* {
        progressVisible ? (
          <div className={css.pollingVin}>
            <div>
              <span style={{width: progress * 50 + '%'}}></span>
            </div>
          </div>
        ): null
      } */}
      
    </>
  );
}

export default Vin
