import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Tooltip from 'rc-tooltip'
import Loading from '@/components/Loading'
import Empty from '@/components/Empty'
import Modal from '@/components/Modal'
import Toast from '@/components/Toast'
import accountApi from '@/api/account'
import styles from './invite.module.scss'

interface PropsIn {
  visible: boolean,
  setVisible: Function,
  setNumberV: Function
}

interface resultIn {
  code: number
  data: any
}



const Invite: React.FC<PropsIn> = (props) => {
  const intl = useIntl()
  const { visible, setVisible, setNumberV } = props
  const history = useHistory()
  const [ data, setData ] = useState<any>([])
  const [ active, setActive ] = useState<string>('invite')

  const [ historyLoading, setHistoryLoading ] = useState<boolean>(false)

  const [ email, setEmail ] = useState<string>('')
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ butText, setButText ] = useState<string>('Send Invitation')

  const [ packageData, setPackageData ] = useState<any>([])
  const [ packageVisible, setPackageVisible ] = useState<boolean>(false)
  const [ packageLoading, setPackageLoading ] = useState<boolean>(false)

  const [ convertStatus, setConvertStatus ] = useState<number>(0)
  const [ numberVisible, setNumberVisible ] = useState<boolean>(false)
  const [ historyTipsVisible, setHistoryTipsVisible ] = useState<boolean>(false)
  
  const tab = [
    {
      name: intl.formatMessage({ id: 'Invite a friend' }),
      val: 'invite'
    },
    // {
    //   name: 'Invitation list',
    //   val: 'history'
    // }
  ]

  useEffect(()=>{
    getInvite('beacon')
    document.onclick = (e) => {
      let elem: any = e.target
      while(elem) {
        if(elem.id === "invite"){
          return
        }
        elem = elem.parentNode
      }
      setVisible(false)
    }
  }, [])


  useEffect(()=>{
    if(active === 'history'){
      setHistoryTipsVisible(false)
      getInvite('list')
    }
  }, [active])
  useEffect(()=>{
    if(visible){
      setActive('invite')
    }
  }, [visible])

  const sendInvite = () => {
    setLoading(true)
    accountApi.sendInvite({email}).then((result: resultIn)=>{
      setLoading(false)
      if(result.code === 1){
        Toast(intl.formatMessage({ id: 'Sent successfully' }))
        setButText('Invitation sent')
        if(!localStorage.getItem('inviteHistoryTips')){
          localStorage.setItem('inviteHistoryTips', '1')
          setHistoryTipsVisible(true)
          setTimeout(()=>{
            setHistoryTipsVisible(false)
          }, 5000)
        }
      }
    })
  }

  const getInvite = (view?: string) => {
    setHistoryLoading(true)
    accountApi.getInvite({type: view || ''}).then((result: any)=>{
      setHistoryLoading(false)
      if(result.code === 1){
        setData(result.data)
        setConvertStatus(result.claim || 0)
        setNumberVisible(result.show)
        setNumberV(result.show)
      }
    })
  }

  const getConvert = () => {
    accountApi.getConvert().then((result: resultIn)=>{
      if(result.code === 1){
        setPackageData(result.data)
        // if(result.data && result.data.length){
        //   if(result.data.length === 1){
        //     setConvert(result.data[0].paypal_subid)
        //     setPackageVisible(false)
        //   }else {
        //     setPackageData(result.data)
        //   }
          
        // }else {
        //   setConvert()
        //   setPackageVisible(false)
        // }
      }
    })
  }
  const setConvert = ()=> {
    setPackageLoading(true)
    accountApi.useConvert({paypal_subid: packageData[0]?.paypal_subid || ''}).then((result: resultIn)=>{
      setPackageLoading(false)
      if(result.code === 1){
        Toast(result.data)
        setPackageVisible(false)
      }
    })
  }
  const emailFocus = () => {
    if(butText === 'Invitation sent'){
      setButText('Send Invitation')
      setEmail('')
    }
  }
  return (
    <>
      {
        visible ? (
          <div className={styles.invite} id="invite">
            <i className='iconfont icona-16pt1' style={{position: 'absolute', top: 4, right: 4, color: '#999', cursor: 'pointer'}} onClick={()=>setVisible(false)} />
            <div className={styles.tab}>
              {
                tab.length === 1 ? (
                  <h6>{tab[0].name}</h6>
                ): (
                  <ul>
                    {
                      tab.map((item)=>{
                        return (
                          <Tooltip id="invite" overlayStyle={{zIndex: 9999, width: 250}} visible={historyTipsVisible && item.val === 'history'} overlayClassName="grey" mouseLeaveDelay={0} placement="bottom" overlay={(<div className='tips'><p>{intl.formatMessage({id: 'clickCheckInvitation'})}</p></div>)}>
                            <li key={item.val} onClick={()=>setActive(item.val)} className={active === item.val ? styles.active : ''}><span>{item.name}{numberVisible && item.val === 'history' ? <i className={styles.number}>1</i> : null}</span></li>
                          </Tooltip>
                        )
                      })
                    }
                  </ul>
                )
              }
              
            </div>
            {
              active === 'invite' ? (
                <div className={styles.content}>
                  {/* <p>{intl.formatMessage({id: 'inviteFriendsGetPackage'})}</p> */}
                  <div className={styles.input}>
                    <input type='email' placeholder={intl.formatMessage({ id: 'Email adress' })} value={email} onFocus={()=>emailFocus()} onChange={(e)=>{setEmail(e.target.value)}} />
                    <i className="iconfont iconxingzhuang1"></i>
                    {/* <p>Invite 10 friends to sign up and get a <em>1-year annual card</em> for the whole department</p> */}
                  </div>
                  <button className={styles.submit} onClick={()=>sendInvite()} disabled={email.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) === -1}>
                    {
                      loading ? <Loading visible={true} type='mini' color="#fff" /> : null
                    }
                    {intl.formatMessage({ id: butText })}
                  </button>
                </div>
              ): (
                <Loading visible={historyLoading}>
                  <div className={styles.content}>
                    {
                      data.length ? (
                        <div className={styles.table}>
                          <table>
                            <tr>
                              <th>{intl.formatMessage({id: 'emailAddress'})}</th>
                              <th>{intl.formatMessage({id: 'status'})}</th>
                              <th>{intl.formatMessage({id: 'redeem'})}</th>
                            </tr>
                            {
                              data.map((item: any, index: number)=>{
                                return (
                                  <tr key={index}>
                                    <td>{item.email}</td>
                                    <td style={{color: item.status === 0 ? '#999999' : '#1890FF'}}>{item.status_str}</td>
                                    <td style={{color: item.reward === 1 ? '#1890FF' : '#999999'}}>• {item.reward_str}</td>
                                  </tr>
                                ) 
                              })
                            }
                          </table>
                        </div>
                      ): (
                        <div style={{paddingTop: 80}}>
                          <Empty />
                        </div>
                        
                      )
                    }
                    <div style={{paddingTop: 10}}>
                      <button disabled={convertStatus === 1 ? false : true} className={styles.submit} onClick={()=>{setPackageVisible(true); getConvert()}}>{convertStatus === 2 ? 'Redeemed' : 'Redeem'}</button>
                      {/* <p className={styles.p}>There are only 1000 rewards</p> */}
                    </div>
                  </div>
                </Loading>
              )
            }
            
          </div>
        ): null
      }
      <Modal
        title="Confirm Voucher application"
        visible={packageVisible}
        cancel={() => setPackageVisible(false)}
        width={400}
      >
        
          <div className={styles.package} id="invite">
            <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'inviteRewardTips'})}}></span>
            {
              packageData.map((item: any, index: number)=>{
                return (
                  <div key={index}>
                    {intl.formatMessage({id: 'currentSubscriptionExpires'})}<em>{item.valid_time}</em>
                  </div>
                )
              })
            }
            <button onClick={()=>setConvert()} disabled={packageLoading}>
              {
                packageLoading ? <Loading visible={true} type='mini' color="#fff" /> : null
              }
              {intl.formatMessage({id: 'confirm'})}
            </button>
          </div>
        
      </Modal>
    </>
    
  )
}

export default Invite