const th = {
  locale: 'th-TH',
  messages: {
    "inputVinBrand": "กรุณาใส่ VIN หรือหมายเลขชิ้นส่วนหรือยี่ห้อ",
    "history": "ประวัติความเป็นมา",
    "home": "บ้าน",
    "catalog": "แคตตาล็อก",
    "search": "ค้นหา",
    "dragFile": "วางหรือลากไฟล์ในพื้นที่นี้เพื่อระบุ",
    "selectImage": "เลือกรูปภาพ",
    "howWorks": "ดูว่ามันทำงานอย่างไร",
    "more": "มากกว่า",
    "helpSection": "ส่วนการช่วยเหลือ",
    "freeTrialDay": "ทดลองใช้ฟรี 3 วัน",
    "account": "บัญชี",
    "purchase": "ซื้อ",
    "message": "ข้อความ",
    "favorites": "รายการโปรด",
    "feedbackMessage": "ข้อเสนอแนะข้อมูล",
    "watch": "รายการเฝ้าดู",
    "coupon": "คูปอง",
    "signIn": "เข้าสู่ระบบ",
    "signOut": "ออกจาก",
    "register": "ลงทะเบียน",
    "subscribe": "สมัครสมาชิก",
    "freeSearch": "ค้นหาฟรี",
    "inviteTips": "คลิกปุ่มเชิญเพื่อน 10 คนมารับบัตรรายปีฟรี",
    "invite": "คำเชิญ",
    "freeTrialTips": "โอ้ ไม่นะ ดูเหมือนว่าคุณจะไม่ได้ค้นหาฟรี! หากต้องการใช้ Partsbond ต่อไปให้เริ่มทดลองใช้ฟรี",
    "freeTrial": "ทดลองใช้ฟรี",
    "helpVideos": "วิดีโอช่วยเหลือ",
    "copyright": "Copyright@2020 บริษัท ออโต้บอนด์ จำกัด (มหาชน)",
    "api": "API",
    "app": "ใบสมัคร",
    "about": "เกี่ยวกับเรา",
    "contact": "ติดต่อเรา",
    "and": "และ",
    "privacy": "ความเป็นส่วนตัวและข้อกำหนด",
    "feedback": "บอกเราว่าคุณคิดอย่างไร",
    "writeOpinion": "เขียนความคิดเห็นของคุณ...",
    "sentUsEmail": "ส่งถึงเรา: aaronw@autobondinc.com",
    "sentUs": "ส่งถึงเรา",
    "iosDownload": "ดาวน์โหลด IOS",
    "androidDownload": "ดาวน์โหลด Android",
    "inputPNMR": "โปรดป้อน Pid / ชื่อ / รุ่น / หมายเหตุ",
    "view3D": "คลิกเพื่อดูโมเดล 3 มิติ",
    "again": "อีกครั้ง",
    "pictures": "รูปภาพ",
    "partsNumber": "หมายเลขชิ้นส่วน",
    "partsName": "ชื่อชิ้นส่วน",
    "priceRange": "ช่วงราคา",
    "operation": "กิจกรรม",
    "email": "อีเมล์",
    "vCode": "รหัสยืนยัน",
    "nPassword": "รหัสผ่านใหม่",
    "cNPassword": "ยืนยันรหัสผ่านใหม่",
    "submit": "ส่ง",
    "getCode": "รับรหัส",
    "countdown": "{num}s อีกครั้ง",
    "inputEmail": "กรุณากรอกอีเมลของคุณ",
    "emailCorrectly": "กรุณากรอกอีเมลให้ถูกต้อง",
    "inputPassword": "กรุณากรอกรหัสผ่าน",
    "passwordsInconsistent": "รหัสผ่านสองรหัสที่คุณป้อนไม่สอดคล้องกัน",
    "sendCodeSuccess": "ส่งโค้ดสำเร็จ",
    "agreeUserAgreement": "กรุณายอมรับข้อตกลงผู้ใช้ Partsbond",
    "tryFree": "ทดลองใช้ฟรี Partsbond",
    "tryFreeDay": "หลังจากเข้าสู่ระบบแตะทดลองใช้ฟรีเพื่อเพิ่มการทดลองใช้อีก 7 วัน",
    "registrationCodeSentSuccess": "ส่งรหัสลงทะเบียนสำเร็จ! หากคุณไม่ได้รับรหัสภายใน 1-2 นาทีให้ลองรีเฟรชกล่องจดหมายของคุณและตรวจสอบโฟลเดอร์สแปม",
    "findUs": "คุณพบเราได้อย่างไร",
    "iAgree": "ฉันเห็นด้วย",
    "userAgreement": "ข้อตกลงผู้ใช้ Partsbond",
    "brand": "แบรนด์",
    "sampleSearch": "ตัวอย่างการค้นหา",
    "passwordChangedSuccess": "เปลี่ยนรหัสผ่านสำเร็จ",
    "passwordChangedFailed": "การแก้ไขรหัสผ่านล้มเหลว",
    "enterNewName": "กรุณาใส่ชื่อผู้ใช้ใหม่",
    "userChangedSuccess": "การเปลี่ยนแปลงผู้ใช้สำเร็จ",
    "userChangedFailed": "การแก้ไขผู้ใช้ล้มเหลว",
    "fillName": "กรุณากรอกชื่อผู้ใช้ก่อน",
    "vinBindingSuccee": "Vin ผูกพันสำเร็จ",
    "success": "ประสบความสำเร็จ",
    "brandPackage": "แพคเกจสอบถามแบรนด์รถยนต์",
    "combo": "สหภาพแรงงาน",
    "validTime": "เวลาที่ถูกต้อง",
    "cancelRenewal": "ยกเลิกการต่ออายุ",
    "packageContent": "เนื้อหาบรรจุภัณฑ์",
    "bindFrame": "ผูกเพิ่มอีก 1 เฟรม",
    "remainingQueries": "จำนวนการสอบถามที่เหลือ",
    "personalInformation": "ข้อมูลส่วนบุคคล",
    "loginAccount": "เข้าสู่ระบบบัญชี",
    "nickName": "Nick Name",
    "modify": "การปรับเปลี่ยน",
    "bindingEmail": "ผูกอีเมล",
    "loginPassword": "รหัสผ่านเข้าสู่ระบบ",
    "enterContent": "ป้อนเนื้อหา",
    "noCoupons": "ไม่มีคูปอง",
    "discountOperation": "การดำเนินการลดราคา",
    "preferentialContent": "เนื้อหาข้อเสนอ",
    "serviceLife": "อายุการใช้งาน",
    "user": "ผู้ใช้",
    "remarks": "รีวิว",
    "noPartsCollection": "ไม่มีการเก็บรวบรวมชิ้นส่วน",
    "selectAll": "เลือกทั้งหมด",
    "delete": "ลบ",
    "vin": "VIN",
    "oe": "OE",
    "detail": "รายละเอียด",
    "noNews": "ไม่มีข้อความ",
    "feedbackDetails": "รายละเอียดข้อเสนอแนะ",
    "feedbackStatus": "สถานะข้อเสนอแนะ",
    "feedbackTime": "เวลาข้อเสนอแนะ",
    "questionType": "ประเภทของปัญหา",
    "problemDescription": "คำอธิบายปัญหา",
    "solved": "แก้",
    "pending": "แขวนอยู่",
    "supplementaryQuestion": "คำถามเพิ่มเติม",
    "endFeedback": "สิ้นสุดข้อเสนอแนะ",
    "cancel": "การยกเลิก",
    "invalidCoupon": "ฉันไม่พบรหัสส่วนลดนี้",
    "bindVin": "ผูก {num}VIN",
    "annualSubscribe": "การสมัครสมาชิกรายปี",
    "currencySymbol": "$",
    "enterNumber": "โปรดป้อนจำนวนเฟรมเดียวตามลำดับ",
    "boundRemaining": "｛hasBound｝/｛remainingBound｝VIN BOUND〔｛has｝／｛remain｝VIN BINDS remaining〕",
    "boundDescribe1": "การสมัครสมาชิกหลายรายการสามารถใช้กับบัญชีเดียว",
    "boundDescribe2": "การสมัครสมาชิกมีอายุ 1 ปีปฏิทิน (นับจากวันที่ซื้อ)",
    "boundDescribe3": "การทำไบนารี่ VIN ให้เป็นการสมัครสมาชิกเป็นการถาวร หากคุณต้องการผูก VIN ในภายหลังคุณสามารถผูกในรายละเอียดบัญชีของคุณ",
    "allInOne": "บูรณาการ",
    "asiaPack": "กระเป๋าเป้สะพายหลังเอเชีย",
    "euroPack": "แพคเกจยูโร",
    "usPack": "บรรจุภัณฑ์อเมริกัน",
    "daySubscription": "สมัครสมาชิกรายวัน",
    "monthSubscription": "สมัครสมาชิกรายเดือน",
    "annualSubscription": "การสมัครสมาชิกรายปี",
    "use": "การใช้",
    "howGetCode": "วิธีรับรหัสส่วนลด",
    "payPalWith": "PayPal",
    "openingPermission": "เปิดสิทธิ์",
    "availablePackages": "แพคเกจโปรแกรมที่มีอยู่",
    "bestMatch": "การจับคู่ที่ดีที่สุด",
    "priceLowHigh": "ราคาต่ำไปสูง",
    "priceHighLow": "ราคาสูงไปต่ำ",
    "pleaseLoginFirst": "กรุณาเข้าสู่ระบบก่อน",
    "here3DArea": "นี่คือพื้นที่ 3D",
    "applicableVehicles": "ยานพาหนะที่ใช้บังคับ",
    "applicableVehicle": "ยานพาหนะที่ใช้บังคับ",
    "interchangeableParts": "ชิ้นส่วนที่เปลี่ยนได้",
    "condition": "เงื่อนไข",
    "all": "ทั้งหมด",
    "channel": "ช่อง",
    "location": "สถานที่",
    "partsFound": "ชิ้นส่วนที่พบ",
    "noData": "ไม่มีข้อมูล",
    "carsModels": "รุ่นรถ",
    "year": "ปี",
    "market": "ตลาดนัด",
    "oeNumber": "หมายเลข OE",
    "partName": "ชื่อชิ้นส่วน",
    "count": "นับ",
    "partsPrice": "ราคาอะไหล่",
    "directReplacement": "เปลี่ยนโดยตรง",
    "indirectReplacement": "การแทนที่ทางอ้อม",
    "priceTrend": "แนวโน้มราคา",
    "partPicture": "รูปภาพส่วนประกอบ",
    "brands": "แบรนด์",
    "replacement": "แทนที่",
    "applicableVeh": "ยานพาหนะที่ใช้บังคับ",
    "supplier": "ผู้ให้บริการ",
    "getIt": "เข้าใจแล้ว",
    "go": "ไป",
    "findResults": "ค้นหาผลลัพธ์สำหรับ {num}",
    "partOe": "อะไหล่ OE",
    "mainGroup": "กลุ่มหลัก",
    "subGroup": "กลุ่มย่อย",
    "group": "กลุ่ม",
    "configDetails": "รายละเอียดการกำหนดค่า",
    "selectGroup": "เลือกกลุ่ม",
    "conflg": "Conflg",
    "picture": "รูปถ่าย",
    "mg": "มิลลิกรัม",
    "sg": "SG",
    "illNo": "ILL-NO",
    "name": "ชื่อ",
    "remark": "รีวิว",
    "model": "รุ่น",
    "selectConfigurationInformation": "เลือกข้อมูลการกำหนดค่า",
    "groupOne": "เราอยู่ในกลุ่มแรก!",
    "lastGroup": "นี่คือกลุ่มสุดท้าย!",
    "list": "รายการ",
    "back": "< กลับ",
    "switchGroup": "แลกเปลี่ยนหน่วย",
    "notFilter": "ไม่มีการคัดกรอง",
    "filter": "ฟิลเตอร์",
    "config": "Config",
    "pos": "สถานที่ตั้ง",
    "qty": "ปริมาณ",
    "addWatch": "เพิ่มไปยังรายการเฝ้าดู",
    "brandPart": "ส่วนแบรนด์",
    "component": "ส่วนประกอบ",
    "disclaimer": "ข้อสงวนสิทธิ์: ผลการสอบถามเป็นเพียงข้อมูลอ้างอิงเท่านั้น",
    "partDetail": "รายละเอียดชิ้นส่วน",
    "vinNotAvailable": "ไม่มี VIN",
    "partNotAvailable": "หมายเลขชิ้นส่วนไม่สามารถใช้ได้",
    "lackModels": "โมเดลที่ขาดหายไป",
    "dataNotComplete": "ข้อมูลไม่สมบูรณ์",
    "dataError": "ข้อผิดพลาดของข้อมูล",
    "dataNotNew": "ข้อมูลใหม่ไม่เพียงพอ",
    "slowSpeed": "ความเร็วช้า",
    "noUniqueness": "ไม่มีเซ็กส์",
    "other": "เพิ่มเติม",
    "selectQuestion": "เลือกคำถาม",
    "close": "กวน",
    "howVin": "ฉันจะตรวจสอบจำนวนเฟรมได้อย่างไร?",
    "howVin1": "ค้นหาหมายเลขเฟรมและบันทึกหมายเลข VIN บนกระจกหน้าต่างด้านหน้าของปริมาณรถ",
    "howVin2": "ป้อนจำนวนเฟรมหรือแตะรูปภาพที่อัปโหลดเพื่อระบุจำนวนเฟรม",
    "howVin3": "คลิกที่ไอคอนแบบสอบถามเพื่อสอบถามจำนวนเฟรม",
    "howVin4": "ป้อนชื่อแบรนด์และคลิกที่ไอคอนสอบถามเพื่อสอบถาม",
    "findParts": "ฉันจะหาชิ้นส่วนได้อย่างไร?",
    "findParts1": "เลื่อนเมาส์ไปที่หมายเลขชั้นวางของที่จอดรถเพื่อดูข้อมูลการกำหนดค่าพื้นฐาน",
    "findParts2": "คลิกที่ไอคอนนี้เพื่อสอบถามเกี่ยวกับชิ้นส่วนตัวถัง ชิ้นส่วนแชสซี และชิ้นส่วนเครื่องยนต์โดยใช้โมเดล 3 มิติ",
    "findParts3": "เลือกกลุ่มหลักและกลุ่มเพื่อเข้าสู่รายการชิ้นส่วน",
    "findParts4": "คลิกเพื่อเลือกกลุ่มหลักและแบบสอบถามจำนวนเฟรมใหม่",
    "findParts5": "คลิกที่หมายเลขชิ้นส่วนของมุมมองการสลายตัวเพื่อเลือกชิ้นส่วน",
    "howPart": "ฉันจะสอบถามหมายเลขชิ้นส่วนได้อย่างไร?",
    "howPart1": "ป้อนหรืออัพโหลดรูปภาพเพื่อระบุหมายเลขชิ้นส่วนคลิกค้นหาเพื่อสอบถาม",
    "howPart2": "คลิกที่ไอคอนเพื่อรวบรวมชิ้นส่วนลงในคอลเลกชันของฉันและคุณจะได้รับการแจ้งเตือนทางอีเมลเมื่อราคาชิ้นส่วนมีการเปลี่ยนแปลง",
    "howPart3": "คลิกที่ไอคอนเพื่อดูแนวโน้มราคาล่าสุดของชิ้นส่วน",
    "noHelp": "ไม่ต้องการความช่วยเหลือ",
    "understood": "ความเข้าใจ",
    "nextStep": "ขั้นตอนต่อไป",
    "clickCheckInvitation": "คลิกที่นี่เพื่อดูสถานะการเชิญ",
    "inviteFriendsGetPackage": "เชิญเพื่อน 10 คนเข้าร่วมการสมัครสมาชิกรายปีของเรา! มูลค่า $540!",
    "emailAddress": "ที่อยู่อีเมล",
    "status": "สถานะ",
    "redeem": "แลก",
    "inviteRewardTips": "<p>กรุณายืนยันการสมัครรับของรางวัล <br /> <em>สมัครสมาชิกรายปี All in One </em></em> p><p>หากคุณมีการสมัครสมาชิกปัจจุบันรางวัลของคุณจะถูกแลกโดยอัตโนมัติเมื่อหมดอายุ</ </p>",
    "currentSubscriptionExpires": "ระยะเวลาการสมัครสมาชิกปัจจุบันของคุณคือ",
    "confirm": "ยืนยัน",
    "putAway": "วางลง",
    "seeAll": "ดูทั้งหมด",
    "pageCannotEmpty": "จำนวนหน้าไม่สามารถว่างได้",
    "pleaseRegisterSignin": "กรุณาลงทะเบียน/เข้าสู่ระบบเพื่อค้นหาข้อมูลรถเพิ่มเติม",
    "price": "ราคา",
    "quantity": "ปริมาณ",
    "pleaseRL": "กรุณาลงทะเบียน / เข้าสู่ระบบ",
    "sign": "เข้าสู่ระบบ",
    "stay": "เข้าสู่ระบบ",
    "forget": "ลืมรหัสผ่าน",
    "password": "รหัสลับ",
    "RFFVS": "ลงทะเบียนฟรี ค้นหา VIN",
    "cName": "ชื่อบริษัท",
    "basic": "พื้นฐาน",
    "updateTime": "อัปเดตเวลา",
    'OfficialStore': 'ร้านค้าอย่างเป็นทางการ',
    "english": "English",
    "russian": "русск",
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語',
    'china': '简体中文',
    'cht': '繁體中文'
  }
}

export default th