import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import MainContext from '@/context/MainContext'
import Tooltip from 'rc-tooltip'
import { PartDetail, PartSearch } from '@/page/Vin'
import Modal, {  } from '@/components/Modal'
import Toast from '@/components/Toast'
import Exploded, { Lite } from '@/components/Exploded'
import Copy from '@/components/Copy'
import Loading from '@/components/Loading'
import { cdnsImgPath, getUrlParams, removeQueryParam } from '@/utils/utils'
import { parabola } from "@/utils/parabola.js"
import axios from 'axios'
import vinApi from '@/api/vinApi'
import accountApi from '@/api/account'
import css from './vin.module.scss'
import styles from '@/page/PartsPrice/partspriceNew.module.scss'
import mainCss from '@/pages/Main/main.module.scss'
import { useIntl } from 'react-intl'
import Trend from '@/components/Trend'


interface PropsIn {
  brandcode: string,
  mcid: string,
  num: string,
  update?: Function,
  vin?: string,
  index?: string,
  subindex?: string,
  type?: string,
  subgroup?: string,
  mid?: string,
  pid?: string,
  finalid?: string,
  backFn?: Function
}

let getPartUpload = false
let getPartTag = false


const VinDetail: React.FC<PropsIn> = (props) => {
  const intl = useIntl()
  const history = useHistory()
  const navigate = (url: string) => history.push(url);
  const { oe } = getUrlParams()
  const { vin = '', brandcode, mcid, num, index, subindex, type, subgroup, mid = '', pid, backFn, finalid } = props
  const { store, setStore } = useContext(MainContext)
  const { crumb, vinData, watchTotal } = store

  const boxContentRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  
  const [ structure, setStructure ] = useState<any>(JSON.parse(sessionStorage.getItem('structure') || '[]'))
  const [ subGroup, setSubGroup ] = useState<any>(JSON.parse(sessionStorage.getItem('subGroup') || '[]'))

  const [ data, setData ] = useState<any>({})
  const [ activeHsKey, setActiveHsKey ] = useState<string>('')

  const [ cntIndex, setCntIndex ] = useState<any>([])
  const [ partKey, setPartKey ] = useState<string>('')
  
  const [ activePart, setActivePart ] = useState<any>({})
  const [ detailPart, setDetailPart ] = useState<any>({})
  const [ filter, setFilter ] = useState<boolean>(localStorage.getItem('filter') ? true : false)
  const [ configVisible, setConfigVisible ] = useState<boolean>(false)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [ config, setConfig ] = useState<any>([])
  
  const [ scrollTo, setScrollTo ] = useState<boolean>(true)
  const [ partDetailVisible, setPartDetailVisible ] = useState<boolean>(false)

  const [trendData, setTrendData] = useState<any>([])
  const [trendVisible, setTrendVisible] = useState<boolean>(false)
  const [trendLoading, setTrendLoading] = useState<boolean>(false)

  const [rpVisible, setRpVisible] = useState<boolean>(false)
  const [rpLoading, setRpLoading] = useState<boolean>(false)
  const [replaceData, setReplaceData] = useState<any>({})
  
  const [cbVisible, setCbVisible] = useState<boolean>(false)
  const [cburl, setCbUrl] = useState<string>(window.sessionStorage.getItem('cburl') || '')
  
  const cbname = window.sessionStorage.getItem('cbname')
  const cbicon = window.sessionStorage.getItem('cbicon')
  
  const [ activeRndPart, setActiveRndPart ] = useState<any>({})

  useEffect(()=>{
    if(type === 'partSearch' || structure.length || subGroup.length){
      if(finalid){
        getMaintenancePart()
      }else {
        getPart()
      }
    }else {
      let sir = subindex?.split(',') || []
      if(sir.length > 1){
        let url = `/vin?vin=${vin}&num=${num}&brandcode=${brandcode}`
        //catalog
        if(!vin){
          url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${num}`
        }
        navigate(url)
      }else {
        getStructure()
      }
      
    }
    if (!type) {
      document.addEventListener('keydown', handleKeydown)
    }
    return (()=>{
      document.removeEventListener('keydown', handleKeydown)
    })
  }, [])

  useEffect(()=>{
    if(activeHsKey){
      scrollToAnchor()
    }
  }, [activeHsKey])

  useEffect(()=>{
    if((pid || oe) && data.data && scrollTo){
      setScrollTo(false)
      scrollToAnchor()
    }
  }, [activePart])

  useEffect(()=>{
    if(getPartUpload){
      if(subindex){
        getSubGroup()
      }else {
        getPart()
      }
    }
  }, [structure])

  useEffect(()=>{
    if(getPartUpload){
      getPart()
    }
  }, [subGroup])

  useEffect(()=>{
    if(getPartTag){
      tag()
    }
  }, [data])

  useEffect(()=>{
    if(detailPart.pid){
      setPartDetailVisible(true)
    }
  }, [detailPart])

  useEffect(()=>{
    if(configVisible){
      searchConfig()
    }
  }, [configVisible])
  
  
  const getStructure = () => {
    const param = {
      vin: vin,
      mcid: mcid,
      brandCode: brandcode,
      num: num
    }
    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1) {
        result.data.forEach((d: any)=>{
          d.img = result.imgs[d.imageLarge] || ''
        })
        getPartUpload = true
        sessionStorage.setItem('structure', JSON.stringify(result.data))
        setStructure(result.data)
      }
    })
  }
  const getSubGroup = () => {
    if(index){
      const item = structure[index]
      const param = {
        vin,
        mcid: item.mcid,
        brandCode: item.brandCode,
        num: item.num,
        subgroup: item.subgroup,
        maingroup: item.maingroup,
        level: 1
      }
      vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
        if(result.code === 1) {
          result.data.forEach((d: any)=>{
            d.img = result.imgs[d.imageLarge] || ''
          })
          getPartUpload = true
          sessionStorage.setItem('subGroup', JSON.stringify(result.data))
          setSubGroup(result.data)
        }
      })
    }
  }
  const getPart = () => {
    let param: any = {}
    if(type === 'partSearch'){
      param = {
        vin,
        brandCode: brandcode,
        mcid: mcid,
        num: num,
        subgroup: subgroup,
        mid: mid
      }
    }else if(index) {
      let item = structure[index] || {}
      if(subindex){
        item = subGroup[subGroup.length - 1][index]
      }
      param = {
        vin,
        brandCode: brandcode,
        mcid: item.mcid,
        num: item.num,
        subgroup: item.subgroup,
        mid: item.mid
      }
    }
    vinApi.points(param).then((result: any)=>{
      if(result.code === 1 && result.data && result.data.information && result.data.information !== '[]' && result.data.cnts && result.data.cnts !== '[]'){
        
        result.data.information = result.data.information ? JSON.parse(result.data.information) : []
        result.data.cnts = result.data.cnts ? JSON.parse(result.data.cnts) : []
        if(boxContentRef.current){
          result.data.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }

        let imageFormat = 'image/png'
        if(result.data.img_name_simple){
          imageFormat = 'image/' + result.data.img_name_simple.substring(result.data.img_name_simple.lastIndexOf('.') + 1)
        }
        result.data.mask = false
        result.data.information.forEach((item: any, index: number)=>{
          for(let key in result.data.cnts){
            let cnt = result.data.cnts[key]
            // let x = 0
            // let y = 0
            if(cnt.itids.indexOf(item.itid_index) !== -1){
              if(!cnt.point){
                cnt.point = []
              }
              cnt.point.push(index)
              cnt.itid_old = item.itid_old
              result.data.mask = true
            }
            // if(cnt.cnts){
            //   cnt.cnts.forEach((c: any)=>{
            //     if(x === 0 || x > c[0]){
            //       x = c[0]
            //     }
            //     if(y === 0 || y > c[1]){
            //       y = c[1]
            //     }
            //   })
            // }
            // cnt.imgXY = [-x, -y]
          }
        })

        vinApi.partnew(param).then((res: any)=>{
          let itid_list: any = []
          let pid_list: any = []
          let tempDetail = {}

          res.data.part_detail.forEach((item: any)=>{
            item.forEach((detail: any)=>{
              if(detail.pnum){
                itid_list.push(detail.pnum)
              }
              if(detail.pid){
                pid_list.push(detail.pid)
              }
              if(detail.pid === pid){
                tempDetail = detail
              }else if(oe && oe === detail.pid){
                tempDetail = detail
              }
              if(!vin){
                detail.colorvalue = 1
              }
              detail.progress = 0

              detail.img = result.data.img_name_simple ? (cdnsImgPath + '/' + result.data.img_name_simple) : result.data.img_name
              
              if(result.data.information){
                result.data.information.forEach((pItem: any)=>{
                  if(pItem.itid_old === detail.pnum && pItem.rect){
                    // detail.rect = pItem.rect
                    detail.img_point = {
                      hotspots: [
                        {
                          "hsX": pItem.rect[0],
                          "hsY": pItem.rect[1],
                          "maxX": pItem.rect[0] + pItem.rect[2],
                          "maxY": pItem.rect[1] + pItem.rect[3],
                          "hsKey": pItem.itid_old
                        }
                      ],
                      maxScaleFactor: "",
                      imageFormat,
                      imageHeight: 1240,
                      imageWidth: 1120
                    }
                  }
                  
                })
              }
            })
          })
          result.data.itid_list = itid_list
          res.points = result.data
          res.pid_list = pid_list
          getPartTag = true
          setData(res)
          setActivePart(tempDetail)
        })
      }else {
        getOldPart(param)
      }
    })
  }
  const getOldPart = (param: any) => {
    vinApi.part(param).then((result: any)=>{
      if(result.code === 1){
        let pid_list: any = []
        if(boxContentRef.current){
          result.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }
        
        result.data.part_detail.forEach((item: any)=>{
          item.forEach((detail: any)=>{
            if(detail.pid){
              pid_list.push(detail.pid)
            }
            if(detail.pid === pid){
              setActivePart(detail)
            }else if(oe && oe === detail.pid){
              setActivePart(detail)
            }
            if(!vin){
              detail.colorvalue = 1
            }
            if(result.img){
              detail.img = result.img.imagePath
            }
            if(result.img && result.img.imgPoints && result.img.imgPoints.hotspots){
              result.img.imgPoints.hotspots.forEach((pItem: any)=>{
                if(pItem.hsKey === detail.pnum){
                  // detail.rect = [pItem.hsX, pItem.hsY, pItem.maxX - pItem.hsX, pItem.maxY - pItem.hsY ]
                  detail.img_point = JSON.parse(JSON.stringify(result.img.imgPoints))
                  detail.img_point.hotspots = [pItem]
                }
              })
            }
          })
        })
        result.pid_list = pid_list
        getPartTag = true
        setData(result)
        
      }
    })
  }
  const getMaintenancePart = () => {
    let item = structure[index || 0]
    const pointParam = {
      brandCode: brandcode,
      mcid: mcid,
      final_id: finalid
    }
    let partParam: any = {
      brandCode: brandcode,
      mcid: mcid,
      vin: vin,
      sub_name: item.standard_subgroup_name,
      label_list: []
    }
    if(item.label_list){
      item.label_list.forEach((label: any)=>{
        partParam.label_list.push(label.standard_label)
      })
      partParam.label_list = partParam.label_list.join(',')
    }
    
    axios.all([vinApi.getMaintenancePoint(pointParam), vinApi.getMaintenancePart(partParam)]).then(axios.spread((pointResult, partResult) => { 
      if(pointResult.code === 1 && partResult.code === 1){
        pointResult.data.imagePath = pointResult.data.img_url
        let pid_list: any = []
        let result: any = {
          data: {
            part_detail: partResult.data
          },
          img: pointResult.data
        }
        if(boxContentRef.current){
          result.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }
        
        result.data.part_detail.forEach((detail: any, index: number)=>{
          if(detail.pid){
            pid_list.push(detail.pid)
          }
          if(detail.pid === pid){
            setActivePart(detail)
          }
          detail.colorvalue = 1
          if(result.img){
            detail.img = result.img.imagePath
          }
          if(result.img && result.img.imgPoints && result.img.imgPoints.hotspots){
            result.img.imgPoints.hotspots.forEach((pItem: any)=>{
              if(pItem.hsKey === detail.hsKey){
                // detail.rect = [pItem.hsX, pItem.hsY, pItem.maxX - pItem.hsX, pItem.maxY - pItem.hsY ]
                detail.img_point = JSON.parse(JSON.stringify(result.img.imgPoints))
                detail.img_point.hotspots = [pItem]
              }
            })
          }
          result.data.part_detail[index] = [detail]
        })
        result.pid_list = pid_list
        getPartTag = true
        setData(result)
      }
    }))
  }
  const anchor = (item: any) => {
    setActivePart(item)
    setActiveHsKey('')
    if(data.points){
      data.points.information.forEach((pItem: any)=>{
        if(pItem.itid_old === item.itid){
          setActivePoint(pItem, true)
        }
      })
    }
  }
  
  const scrollToAnchor = () => {
    if(listRef.current){
      let anchorElement = listRef.current.getElementsByClassName("svg-part-active")[0]
      if(anchorElement) { anchorElement.scrollIntoView() }
    }
    
  }

  const setFilterLS = () => {
    let val = filter ? false : true
    setFilter(val)
    
    if(val){
      localStorage.setItem('filter', '1')
    }else {
      localStorage.removeItem('filter')
      if(index){
        let indexTemp: number[] = []
        let i = parseFloat(index)
        if(subindex){
          subGroup[subGroup.length - 1].forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }else {
          structure.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }
        
        if(indexTemp.indexOf(i) === -1){
          i = indexTemp[0]
          let item = structure[i]
          if(subindex){
            item = subGroup[subGroup.length - 1][i]
          }
          let param = `brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${i}`
          if(subindex){
            param += `&subindex=${subindex}`
          }
          let url = `/group/detail?${param}`
          if(vin){
            url = `/vin/detail?vin=${vin}&${param}`
          }
          navigate(url)
          if(props.update){
            props.update()
          }
        }
      }
    }
  }
  const setGroup = (type: string) => {
    
    if((structure.length && index) && (( subGroup.length && subindex) || !subindex)){
      let i = parseFloat(index)
      if(filter){
        if(type === 'before'){
          if(i){
            i--
          }
        }
        if(type === 'next'){
          if(subindex){
            if(i < subGroup[subGroup.length - 1].length - 1){
              i++
            }
          }else {
            if(i < structure.length - 1){
              i++
            }
          }
          
        }
      }else {
        let indexTemp: number[] = []
        if(subindex){
          subGroup[subGroup.length - 1].forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }else {
          structure.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }
        if(type === 'before'){
          if(indexTemp.indexOf(i) > 0){
            i = indexTemp[indexTemp.indexOf(i) - 1]
          }
        }
        if(type === 'next'){
          if(indexTemp.indexOf(i) < indexTemp.length - 1){
            i = indexTemp[indexTemp.indexOf(i) + 1]
          }
        }
      }
      
      let param = ''
      let item: any = {}
      if(subindex){
        if(i === parseFloat(index)){
          Toast(type === 'before' ? intl.formatMessage({id: 'groupOne'}) : intl.formatMessage({id: 'lastGroup'}), 'warning')
          return
        }
        item = subGroup[subGroup.length - 1][i]
        param = `brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${i}&subindex=${subindex}`
      }else {
        if(i === parseFloat(index)){
          Toast(type === 'before' ? intl.formatMessage({id: 'groupOne'}) : intl.formatMessage({id: 'lastGroup'}), 'warning')
          return
        }
        item = structure[i]
        param = `brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${i}`
        if(finalid){
          param += `&finalid=${item.final_id}`
        }
      }
      item.type = 'detail'
      item.index = crumb[crumb.length - 1].index
      crumb.pop()
      crumb.push(item)
      setStore({value: crumb, type: 'crumb'})
      window.sessionStorage.setItem('crumb', JSON.stringify(crumb))
      
      let url = `/group/detail?${param}`
      if(vin){
        url = `/vin/detail?vin=${vin}&${param}`
      }
      navigate(url)
      if(props.update){
        props.update()
      }
    }
  }
  const searchConfig = (key_words?: string) => {
    if(key_words){
      vinApi.config({vin, key_words}).then((result: {code: number, data: any})=>{
        if(result.code === 1){
          setConfig(result.data.confs)
        }else {
          setConfig([])
        }
      })
    }else {
      setConfig([])
    }
  }
  const back = () => {
    let param = `vin=${vin}&num=${num}`
    if(!vin){
      param = `mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${num}`
    }else if(vinData.data) {
      param += `&vinmcid=${mcid.replace(/\=/g, '%3D')}&vincode=${brandcode}`
    }
    if(subindex){
      param += `&subindex=${subindex}`
    }

    let url = `/vin?${param}`
    if(!vin){
      url = `/group?${param}`
    }
    // let groupCrumb = window.sessionStorage.getItem('groupCrumb') || '[]'
    let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
    tempCrumb.pop()
    window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb || []))
    setStore({value: tempCrumb, type: 'crumb'})
    navigate(url)
  }
  
  const tag = () => {
    let tempData = JSON.parse(JSON.stringify(data))
    let param = {
      vin,
      brandCode: brandcode,
      pid_list: tempData?.pid_list?.join(',')
    }
    getPartTag = false
    if(!param.pid_list){
      return
    }
    axios.all([vinApi.tag(param), vinApi.getStandardLabel({brandCode: brandcode, pids: param.pid_list})]).then(axios.spread((result, re) => { 
      if(tempData.data){
        tempData.data.part_detail.forEach((item: any)=>{
          item.forEach((detail: any)=>{
            if(result.code === 1 && result.data[detail.pid]){
              detail.tag = result.data[detail.pid]
            }
            if(re.code === 1 && re.data[detail.pid]){
              detail.standardLabel = re.data[detail.pid].standard_label
            }
          })
        })
        setData(tempData)
      }
    }))
    // vinApi.tag(param).then((result: any)=>{
    //   if(result.code === 1){
    //     vinApi.getStandardLabel({brandCode: brandcode, pids: param.pid_list}).then((re: any)=>{
    //       if(re.code === 1){
    //         tempData.data.part_detail.forEach((item: any)=>{
    //           item.forEach((detail: any)=>{
    //             if(result.data[detail.pid]){
    //               detail.tag = result.data[detail.pid]
    //             }
    //             if(re.data[detail.pid]){
    //               detail.standardLabel = re.data[detail.pid].standard_label
    //             }
    //           })
    //         })
    //         setData(tempData)
    //       }
    //     })
    //   }
    // })

  }
  const hsKeyAnchor = (val: string) => {
    setActivePart({})
    setActiveHsKey(val)
  }
  const setPoints = (points: any) => {
    let temp = JSON.parse(JSON.stringify(data))
    temp.points = points
    setData(temp)
  }

  const setActivePoint = (item: any, isKey?: boolean) => {
    let cntIndex = item.marks || []
    if(item.itids){
      cntIndex = [item.mark]
    }
    setCntIndex(cntIndex)
    if(!isKey){
      hsKeyAnchor(item.itid_old)
    }
    
  }
  

  const replace = (param: {pid: string, brandCode: string}) => {
    setReplaceData({})
    setRpVisible(true)
    setRpLoading(true)
    vinApi.replace(param).then((result: any)=>{
      setRpLoading(false)
      if(result.code === 1){
        let directly: any = []
        let indirect: any = []
        result.data.forEach((item: {isre: string}) => {
          if(item.isre === "1" || item.isre === "3"){
            directly.push(item)
          }
          if(item.isre === "0"){
            indirect.push(item)
          }
        })
        
        setReplaceData({directly, indirect})
      }
    })
  }
  const getSvgImgRect = () => {
    let svgImgRect = {}
    if(boxContentRef.current){
      svgImgRect = boxContentRef.current.getBoundingClientRect()
    }
    return svgImgRect
  }
  const handleKeydown = (e: any) => {
    if (e.keyCode === 37) { 
      setGroup('before')
    } else if (e.keyCode === 39) {
      setGroup('next')
    }
  }

  const setFavourite = (index: number, i: number) => {
    let temp = {...data}
    let item = temp.data.part_detail[index][i]
    let val = item.tag.hasfavorite ? false : true
    item.tag.hasfavorite = val
    setData(temp)
    
    let param: any = {
      pid: item.pid,
      brandCode: brandcode
    }
    let api = accountApi.favouriteDel
    if(val){
      api = accountApi.favouriteAdd
      param.name = item.label
    }
    api(param).then((result: any)=>{
      if(result.code === 1){
        let wt = watchTotal - 1
        if(val){
          wt = watchTotal + 1
        }
        setStore({value: wt, type: 'watchTotal'})
      }
    })
  }
  return (
    <>
      {/* {
        !type && index ? (
          <div className={mainCss['main-box']} >
            <Breadcrumb />
          </div>
        ): null
      } */}
      
      <div className={!type ? css['vin'] : css['vin'] + ' ' + css['no-mg']}>
        <div className={css['l']}>
          <div className={css['box']}>
            <div className={css['box-top']}>
              <h2>{data.data && data.data.mid}</h2>
              {/* <div className={css['box-top-tools']}>
                <span>
                  <input type="text" placeholder="Maingroup Search" />
                  <i className="iconfont iconsousuo" />
                </span>
              </div> */}
            </div>
            <div ref={boxContentRef} className={css['box-content']}>
              {
                data.svgImgRect ? (
                  <Lite data={data} activeHsKey={activeHsKey} activePart={activePart} hsKeyAnchor={hsKeyAnchor} />
                ): null
              }
              {
                data.points ? (
                  <Exploded data={data.points} setPoints={setPoints} cntIndex={cntIndex} setActivePoint={setActivePoint} activeHsKey={activeHsKey} activePart={activePart} />
                ): null
              }
            </div>
          </div>
        </div>
        <div className={css['r']}>
          <div className={css['box']}>
            <div className={css['box-top']}>
              <div className="flex-s-c">
                <h2>{intl.formatMessage({id: 'list'})}</h2>
                {
                  !type ? (
                    <button onClick={()=>back()} className={css['other-r'] + ' ' + css['back']}><i className="iconfont iconleft" />{intl.formatMessage({id: 'back'})}</button>
                  ): (
                    <>
                      {
                        backFn ? (
                          <button onClick={()=>backFn()} className={css['other-r'] + ' ' + css['back']}><i className="iconfont iconleft" />{intl.formatMessage({id: 'back'})}</button>
                        ): null
                      }
                    </>
                  )
                }
              </div>
              
              <div className={css['box-top-tools']}>
                {
                  !type ? (
                    <>
                      {/* <button onClick={()=>back()} className={css['other-r']}><i className="iconfont iconleft" />{intl.formatMessage({id: 'back'})}</button> */}
                      <button onClick={()=>setGroup('before')}><i className="iconfont iconshangyifenzu" /></button>
                      <em>{intl.formatMessage({id: 'switchGroup'})}</em>
                      <button onClick={()=>setGroup('next')}><i className="iconfont iconxiayifenzu" /></button>

                      {
                        vin ? (
                          <>
                            <button onClick={()=>setConfigVisible(true)} className={css['other']}><i className="iconfont iconpeizhi" style={{color: '#1890FF'}} />{intl.formatMessage({id: 'config'})}</button>
                            <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont iconyiguolv" style={{color: '#616BF1'}} />{filter ? intl.formatMessage({id: 'notFilter'}) : intl.formatMessage({id: 'filter'})}</button>
                          </>
                        ): null
                      }
                      
                      {/* <span>
                        <input type="text" placeholder="OE Number" onChange={(e)=>setPartKey(e.target.value)} />
                        <i className="iconfont iconsousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                      </span> */}
                    </>
                  ): (
                    <>
                      {/* {
                        backFn ? (
                          <button onClick={()=>backFn()} className={css['other-r']}><i className="iconfont iconleft" />{intl.formatMessage({id: 'back'})}</button>
                        ): null
                      } */}
                      {
                        vin ? (
                          <>
                            <button onClick={()=>setConfigVisible(true)} className={css['other']}><i className="iconfont iconpeizhi" style={{color: '#1890FF'}} />{intl.formatMessage({id: 'config'})}</button>
                            <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont iconyiguolv" style={{color: '#616BF1'}} />{filter ? intl.formatMessage({id: 'notFilter'}) : intl.formatMessage({id: 'filter'})}</button>
                          </>
                        ): null
                      }
                    </>
                  )
                }
                
              </div>
            </div>
            <div className={css['box-content']}>
              <table className={css['table-header']}>
                <thead>
                  <tr>
                    <th style={{width: '5%'}}>{intl.formatMessage({id: 'pos'})}</th>
                    <th style={{width: '20%'}}>{intl.formatMessage({id: 'partOe'})}</th>
                    <th style={{width: '15%'}}>{intl.formatMessage({id: 'name'})}</th>
                    <th style={{width: '5%'}}>{intl.formatMessage({id: 'qty'})}</th>
                    <th style={{width: '15%'}}>{intl.formatMessage({id: 'model'})}</th>
                    <th style={{width: '15%'}}>{intl.formatMessage({id: 'detail'})}</th>
                    <th style={{width: '12%'}}>{intl.formatMessage({id: 'price'})}</th>
                    {/* <th style={{width: '8%'}}>Notes</th> */}
                    <th style={{width: '13%'}}>{intl.formatMessage({id: 'remark'})}</th>
                  </tr>
                </thead>
              </table>
              <div className={css['list']} ref={listRef}>
                <table>
                  <tbody>
                    {
                      data.data && data.data.part_detail && data.data.part_detail.map((item: any, index: number)=>{
                        return (
                          item.map((part: any, i: number)=>{
                            let style = ''
                            
                            if(activeHsKey){
                              style = activeHsKey === part.pnum ? `svg-part-active ${css['svg-part-active']}` : ""
                              if((!i || activeHsKey !== item[i-1].pnum || (!filter && !item[i-1].colorvalue)) && activeHsKey === part.pnum){
                                style = style + ' ' + css['svg-part-active-top']
                              }
                              if((i === item.length - 1 || activeHsKey !== item[i+1].pnum || (!filter && !item[i+1].colorvalue)) && activeHsKey === part.pnum){
                                style = style + ' ' + css['svg-part-active-bottom']
                              }
                            }
                            if(activePart.itid && activePart.pid === part.pid && activePart.itid === part.itid){
                              style = 'svg-part-active' + ' ' + css['svg-part-active'] + ' ' + css['svg-part-active-top'] + ' ' + css['svg-part-active-bottom']
                            }
                            if(!part.colorvalue){
                              style = style + ' ' + css['filter']
                            }
                            return (
                              filter || part.colorvalue ? (
                                <tr key={index + '' + i} className={style} onClick={()=>anchor(part)}>
                                  <td style={{width: '5%'}}>{part.itid}</td>
                                  <td style={{width: '20%'}}><Copy title={part.pid} /></td>
                                  <td style={{width: '15%'}} dangerouslySetInnerHTML={{__html: part.label}}></td>
                                  <td style={{width: '5%'}} dangerouslySetInnerHTML={{__html: part.quantity}}></td>
                                  <td style={{width: '15%'}} dangerouslySetInnerHTML={{__html: part.model}}></td>
                                  <td style={{width: '15%'}} dangerouslySetInnerHTML={{__html: part.remark}}></td>
                                  <td style={{width: '12%'}}>
                                    {/* {
                                      part.pid ? (
                                        part.progress ? (
                                          <div className={css.polling}><span style={{width: part.progress * 20 + '%'}}></span></div>
                                        ): (
                                          part.tag?.sale_price ? (
                                              <div className={css.trendIcon}>
                                                {
                                                  type === 'partSearch' ? <>{part.tag.sale_price}</> : <Link className={css.link} to={`/partsprice?pid=${part.pid}`}>{part.tag.sale_price}</Link>
                                                }
                                                <i style={{marginLeft: 4}} className="iconfont iconlingjianzhishu" onClick={()=>showTrend({pid: part.pid, part_name: part.label})} />
                                              </div>
                                            ): (
                                            <span className={css.link} onClick={()=>getPras(urlParams, index, i)}>Get it</span>
                                          )
                                        )
                                      ): null
                                    } */}
                                    {/* {
                                      part.pid ? (
                                        <div className={css.partsprice}>
                                            {
                                              type === 'partSearch' ? <></> : <Link className={css.pricelink} to={`/partsprice?pid=${part.pid}`}>PartsPrice</Link>
                                            }
                                        </div>
                                      ): null
                                    } */}
                                    {
                                      part.pid ? <Link className={css.link} to={`/partsprice?pid=${part.pid}`}><div className={css.partsprice}>{intl.formatMessage({id: 'price'})}</div></Link> : null
                                    }
                                    {/* {
                                      type === 'partSearch' ? <></> : <Link className={css.link} to={`/partsprice?pid=${part.pid}`}>PartsPrice</Link>
                                    } */}
                                  </td>
                                  {/* <td style={{width: '8%'}}>
                                    {
                                      part.tag?.isreplace ? (
                                        <Tooltip mouseLeaveDelay={0} placement="top" overlay='Replacement'>
                                          <span style={{color:'#FF781C'}} className="iconfont iconReplacement"></span>
                                        </Tooltip>
                                      ): null
                                    }
                                    {
                                      part.tag?.has_article ? (
                                        <Tooltip mouseLeaveDelay={0} placement="top" overlay='Brand code'>
                                          <span style={{color:'#E6B422'}} className="iconfont iconbrand"></span>
                                        </Tooltip>
                                      ): null
                                    }
                                    {
                                      part.tag?.iscomt ? (
                                        <Tooltip mouseLeaveDelay={0} placement="top" overlay='Brand code'>
                                          <span style={{color:'#FFA000'}} className="iconfont iconcomponent"></span>
                                        </Tooltip>
                                      ): null
                                    }
                                  </td> */}
                                  <td style={{width: '13%'}}>
                                    
                                    {
                                      part.pid ? (
                                        <div className={css.operation}>
                                          <Tooltip mouseLeaveDelay={0} placement="top" overlay={intl.formatMessage({id: 'addWatch'})} overlayStyle={{zIndex: 9999}}>
                                            <span className={part.tag?.hasfavorite ? 'iconfont iconyishoucang1' : 'iconfont iconshoucangmoren'} style={{fontSize: 18}} onClick={()=>setFavourite(index, i)}></span>
                                          </Tooltip>
                                          <Tooltip mouseLeaveDelay={0} placement="top" overlay={intl.formatMessage({id: 'detail'})} overlayStyle={{zIndex: 9999}}>
                                            <span className="iconfont iconxiangqing" style={{fontSize: 14}} onClick={()=>setDetailPart(part)}></span>
                                          </Tooltip>
                                          {
                                            part.tag?.isreplace ? (
                                              <Tooltip mouseLeaveDelay={0} placement="top" overlay={intl.formatMessage({id: 'replacement'})} overlayStyle={{zIndex: 9999}}>
                                                <span className="iconfont iconReplacement" onClick={()=>replace({pid: part.pid, brandCode: brandcode})}></span>
                                              </Tooltip>
                                            ): null
                                          }
                                          {
                                            part.tag?.has_article ? (
                                              <Tooltip mouseLeaveDelay={0} placement="top" overlay={intl.formatMessage({id: 'brandPart'})} overlayStyle={{zIndex: 9999}}>
                                                <span className="iconfont iconbrand"></span>
                                              </Tooltip>
                                            ): null
                                          }
                                          {
                                            part.tag?.iscomt ? (
                                              <Tooltip mouseLeaveDelay={0} placement="top" overlay={intl.formatMessage({id: 'component'})} overlayStyle={{zIndex: 9999}}>
                                                <span className="iconfont iconcomponent"></span>
                                              </Tooltip>
                                            ): null
                                          }
                                          {/* <Tooltip mouseLeaveDelay={0} placement="top" overlay='Parts Price'>
                                            <Link to={`/partsprice?pid=${part.pid}`}><span className="iconfont iconbijia"></span></Link>
                                          </Tooltip> */}
                                        </div>
                                        
                                      ) : null
                                    }
                                  </td>
                                </tr>
                              ): null
                            )
                          })
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className={css['footer']}>
          {intl.formatMessage({id: 'disclaimer'})}
        </div>
      </div>
      <Modal
        title={intl.formatMessage({id: 'config'})}
        visible={configVisible}
        cancel={setConfigVisible}
      >
        <div className={css['config']}>
          <div className={css['search']}>
            <input placeholder="Please input key" onChange={(e)=>searchConfig(e.target.value)} />
          </div>
          <div className={css['content']}>
            {
              config.map((item: {title: string, value: string}, index: number)=>{
                return (
                  <div className={css['item']} key={index}>
                    <label>{item.title}</label>
                    <span>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partKey}
            mcid={mcid}
            brandcode={brandcode}
            vin={vin}
          />
        ): null
      }
      {
        partDetailVisible ? (
          <Modal
            title={intl.formatMessage({id: 'partDetail'})}
            visible={true}
            cancel={()=>{setPartDetailVisible(false); setDetailPart({})}}
            width={1000}
          >
            <PartDetail vin={vin} pid={detailPart.pid} brandCode={brandcode} mcid={mcid} mid={mid} num={num} queryType={vin ? 'vin' : 'cars'} />
          </Modal>
          
        ) : null
      }
      <Modal
        visible={trendVisible}
        cancel={() => setTrendVisible(false)}
        width={1000}
      >
        <div className={css.trend}>
          <h5>{intl.formatMessage({id: 'priceTrend'})}</h5>
          <Loading visible={trendLoading}>
            {
              trendData.length ? <div className={css.chart}><Trend data={trendData} /></div> : (
                <div className={css.no}>{intl.formatMessage({id: 'noData'})}</div>
              )
            }
            
          </Loading>
        </div>
      </Modal>
      <Modal
        visible={rpVisible}
        cancel={() => setRpVisible(false)}
        width={1000}
      >
        <div className={styles['vehicle-brand-wrap']}>
          <div className={styles['vehicle-select']}>
            <span className={styles['vehicle-title']}>{intl.formatMessage({id: 'interchangeableParts'})}</span>
          </div>
          <div className={styles['vehicle-table-h']}>
            <table className={styles['table-header']}>
              <thead>
                <tr>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'brands'})}</th>
                  <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({id: 'oeNumber'})}</th>
                  <th style={{width: '20%', textAlign: 'center'}}>{intl.formatMessage({id: 'partName'})}</th>
                  <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({id: 'count'})}</th>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'detail'})}</th>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'price'})}</th>
                </tr>
              </thead>
            </table>
            <Loading visible={rpLoading}>
              <div className={styles.maxh}>
                {
                  replaceData.directly?.length ? (
                    <table>
                      <caption>{intl.formatMessage({id: 'directReplacement'})}</caption>
                      <tbody>
                        {
                          replaceData.directly.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icondengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><Copy title={pid.pid} icon /></p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i:number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
                {
                  replaceData.indirect?.length ? (
                    <table>
                      <caption>{intl.formatMessage({id: 'indirectReplacement'})}</caption>
                      <tbody>
                        {
                          replaceData.indirect.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icondengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}>{pid.pid}</p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}>{pid.pid}</p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
              </div>
            </Loading>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VinDetail
