const en = {
  locale: 'en-US',
  messages: {
    'inputVinBrand': 'Please Input VIN or Part Number or Brand',
    'history': 'Historical records',
    'home': 'Home',
    'catalog': 'Catalog',
    'search': 'Search',
    'dragFile': 'Paste or drag file to this area to recognition',
    'selectImage': 'Select Image',
    'howWorks': 'See How It Works',
    'more': 'More',
    'helpSection': 'Help Section',
    'freeTrialDay': 'Free 3 day trial',
    'account': 'Account',
    'purchase': 'Purchase',
    'message': 'Message',
    'favorites': 'Favorites',
    'feedbackMessage': 'Feedback message',
    'watch': 'Watch List',
    'coupon': 'Coupon',
    'signIn': 'Sign In',
    'signOut': 'Sign out',
    'register': 'Register',
    'subscribe': 'Subscribe',
    'freeSearch': 'Free search available',
    'inviteTips': 'Click the button Invite 10 friends to get a FREE year pass',
    'invite': 'Invite',
    'freeTrialTips': "Oh no! It looks like you're out of free searches! To continue using Partsbond, start your Free Trial",
    'freeTrial': 'Free trial',
    'helpVideos': 'Help Videos',
    'copyright': 'Copyright@2020 AutoBond Inc.All Rights Reserved',
    'api': 'API',
    'app': 'App',
    'about': 'About Us',
    'contact': 'Contact Us',
    'and': 'and',
    'privacy': 'Privacy & Terms',
    'feedback': 'Tell us what you think',
    'writeOpinion': 'Write your opinion …',
    'sentUsEmail': 'sent to us : aaronw@autobondinc.com',
    'sentUs': 'Sent to us',
    'iosDownload': 'IOS download',
    'androidDownload': 'Android download',
    'inputPNMR': 'Please input Pid/Name/Model/Remark',
    'view3D': 'Click to View 3D Model',
    'again': 'Again',
    'pictures': 'Pictures',
    'partsNumber': 'Parts Number',
    'partsName': 'Parts Name',
    'priceRange': 'Price Range',
    'operation': 'Operation',
    'email': 'Email',
    'vCode': 'Verification code',
    'nPassword': 'New password',
    'cNPassword': 'Confirm new password',
    'submit': 'Submit',
    'getCode': 'Get code',
    'countdown': '{num}s again',
    'inputEmail': 'Please input your email',
    'emailCorrectly': 'Please fill in the email correctly',
    'inputPassword': 'Please input your password',
    'passwordsInconsistent': 'Two passwords that you enter is inconsistent',
    'sendCodeSuccess': 'Send code successfully',
    'agreeUserAgreement': 'Please agree Partsbond User Agreement',
    'tryFree': 'Try partsbond for free',
    'tryFreeDay': 'After logging in, click free Trial to add another 7-day trial',
    'registrationCodeSentSuccess': 'Registration code sent successfully! If you have not received the code withing 1-2 minutes, please try refreshing your inbox and checking your spam folder.',
    'findUs': 'How did you find us',
    'iAgree': 'I agree',
    'userAgreement': 'Partsbond User Agreement',
    'brand': 'Brand',
    'sampleSearch': 'Sample Search',
    'passwordChangedSuccess': 'Password changed successfully',
    'passwordChangedFailed': 'Failed to modify password',
    'enterNewName': 'Please enter a new user name',
    'userChangedSuccess': 'User changed successfully',
    'userChangedFailed': 'Failed to modify User',
    'fillName': 'Please fill in the user name first',
    'vinBindingSuccee': 'Vin binding succeeded',
    'success': 'successfully',
    'brandPackage': 'Auto Brand Query Package',
    'combo': 'Combo',
    'validTime': 'Valid time',
    'cancelRenewal': 'Cancel renewal',
    'packageContent': 'Package content',
    'bindFrame': 'Bind 1 more frame numbers',
    'remainingQueries': 'Number of remaining queries',
    'personalInformation': 'Personal information',
    'loginAccount': 'Login account',
    'nickName': 'Nick Name',
    'modify': 'Modify',
    'bindingEmail': 'Binding email',
    'loginPassword': 'The login password',
    'enterContent': 'Enter content',
    'noCoupons': 'No coupons',
    'discountOperation': 'Discount operation',
    'preferentialContent': 'Preferential content',
    'serviceLife': 'Service life',
    'user': 'User',
    'remarks': 'Remarks',
    'noPartsCollection': 'No parts in collection',
    'selectAll': 'Select All',
    'delete': 'Delete',
    'vin': 'VIN',
    'oe': 'OE',
    'detail': 'Detail',
    'noNews': 'No news',
    'feedbackDetails': 'Feedback details',
    'feedbackStatus': 'Feedback status',
    'feedbackTime': 'Feedback time',
    'questionType': 'Question type',
    'problemDescription': 'Problem Description',
    'solved': 'solved',
    'pending': 'Pending',
    'supplementaryQuestion': 'Supplementary question',
    'endFeedback': 'End feedback',
    'cancel': 'Cancel',
    'invalidCoupon': "I didn't find this discount code",
    'bindVin': 'Bind {num} VIN',
    'annualSubscribe': 'ANNUAL SUBSCRIPTION',
    'currencySymbol': '$',
    'enterNumber': 'Please enter a single frame number in turn',
    'boundRemaining': '{hasBound}/{remainingBound} VIN BOUND [{has}/{remaining} VIN BINDS REMAINING]',
    'boundDescribe1': 'Multiple subscriptions can be applied to one account',
    'boundDescribe2': 'Subscriptions are good for 1 calendar year (from the date of purchase)',
    'boundDescribe3': 'Bining a VIN to a subscription is permanent; if you wish to bind your VIN(s) later, you may do so within your account details',
    'allInOne': 'ALL-IN-ONE',
    'asiaPack': 'ASIA PACK',
    'CN PACK': 'CN PACK',
    'JPN-KOR PACK': 'JPN-KOR PACK',
    'euroPack': 'EURO PACK',
    'usPack': 'US PACK',
    'daySubscription': 'DAY SUBSCRIPTION',
    'monthSubscription': 'MONTH SUBSCRIPTION',
    'annualSubscription': 'ANNUAL SUBSCRIPTION',
    'use': 'Use',
    'howGetCode': 'How to get a discount code',
    'payPalWith': 'PayPal with',
    'openingPermission': 'Opening permission',
    'availablePackages': 'Available Packages',
    'bestMatch': 'Best Match',
    'priceLowHigh': 'Price Low to High',
    'priceHighLow': 'Price High to Low',
    'pleaseLoginFirst': 'Please login first',
    'here3DArea': 'Here is the 3D area',
    'applicableVehicles': 'Applicable Vehicle(s)',
    'applicableVehicle': 'Applicable Vehicle',
    'interchangeableParts': 'Interchangeable Parts',
    'condition': 'Condition',
    'all': 'All',
    'channel': 'Channel',
    'location': 'Location',
    'partsFound': 'Parts Found',
    'noData': 'NO data',
    'carsModels': 'Cars Model(s)',
    'year': 'Year',
    'market': 'Market',
    'oeNumber': 'OE Number',
    'partName': 'Part Name',
    'count': 'Count',
    'partsPrice': 'PartsPrice',
    'directReplacement': 'Direct replacement',
    'indirectReplacement': 'Indirect replacement',
    'priceTrend': 'Price trend',
    'partPicture': 'Part picture',
    'brands': 'Brands',
    'replacement': 'Replacement',
    'applicableVeh': 'Applicable Veh',
    'supplier': 'Supplier',
    'getIt': 'Get it',
    'go': 'Go',
    'findResults': 'Find about {num} related results for you',
    'partOe': 'Part OE',
    'mainGroup': 'Main group',
    'subGroup': 'Sub group',
    'group': 'Group',
    'configDetails': 'Configuration details',
    'selectGroup': 'Select group',
    'conflg': 'Conflg',
    'picture': 'Picture',
    'mg': 'MG',
    'sg': 'SG',
    'illNo': 'ILL-NO',
    'name': 'Name',
    'remark': 'Remark',
    'model': 'Model',
    'selectConfigurationInformation': 'Select configuration information',
    'groupOne': 'We are in group one !',
    'lastGroup': 'It\'s the last group !',
    'list': 'List',
    'back': 'back',
    'switchGroup': 'Switch group',
    'notFilter': 'Not Filter',
    'filter': 'Filter',
    'config': 'Config',
    'pos': 'POS',
    'qty': 'QTY',
    'addWatch': 'add to watch list',
    'brandPart': 'Brand Part',
    'component': 'Component',
    'disclaimer': 'Disclaimer: query results are for reference only.',
    'partDetail': 'Part Detail',
    'vinNotAvailable': 'The VIN number is not available',
    'partNotAvailable': 'The part number is not available',
    'lackModels': 'The lack of models',
    'dataNotComplete': 'The data is not complete',
    'dataError': 'Data error',
    'dataNotNew': 'The data is not new enough',
    'slowSpeed': 'Slow speed',
    'noUniqueness': 'There is no uniqueness',
    'other': 'Other',
    'selectQuestion': 'Select question',
    'close': 'Close',
    'howVin': 'How to check the frame number?',
    'howVin1': 'Find the frame number and record the VIN number on the front window glass of the vehicle volume',
    'howVin2': 'Enter the frame number or click to upload the picture to identify the frame number',
    'howVin3': 'Click the query icon to query the frame number',
    'howVin4': 'Enter the brand name and click the query icon to query',
    'findParts': 'How to find parts?',
    'findParts1': 'Hover the mouse on the parking rack number to view the basic configuration information',
    'findParts2': 'Click this icon to use the 3D model to query body parts, chassis parts and engine parts',
    'findParts3': 'Select the main group and group to enter the parts list',
    'findParts4': 'Click to re-select the main group group and frame number query',
    'findParts5': 'Click on the part number of the exploded view to select the part',
    'howPart': 'How to use part number query?',
    'howPart1': 'Enter or upload a picture to identify the part number, click search to query',
    'howPart2': 'Click on the icon to collect the part to my collection, and you will be notified by email when the price of the part changes',
    'howPart3': 'Click on the icon to view the recent price trend of the part',
    'noHelp': 'No help needed',
    'understood': 'Understood',
    'nextStep': 'Next step',
    'clickCheckInvitation': 'click here to check invitation status',
    'inviteFriendsGetPackage': 'Invite 10 friends to get an ANNUAL SUBSCRIPTION, on us! Valued at $540!',
    'emailAddress': 'Email address',
    'status': 'status',
    'redeem': 'redeem',
    'inviteRewardTips': '<p>Please confirm to apply your reward of <br /><em>All-in-One annual subscription</em>.</p><p>If you have a current valid subscription, your reward will be redeemed automatically upon expiration.</p>',
    'currentSubscriptionExpires': 'Your current subscription expires is',
    'confirm': 'Confirm',
    'putAway': 'Put Away',
    'seeAll': 'See All',
    'pageCannotEmpty': 'The number of pages cannot be empty',
    'pleaseRegisterSignin': 'Please register/sign in to search for more vehicle data',
    'price': 'Price',
    'quantity': 'Quantity',
    'pleaseRL': 'Please register/login in',
    'sign': 'Sign in',
    'stay': 'Stay logged in',
    'forget': 'Forget Password',
    'password': 'Password',
    'RFFVS': 'Register for Free VIN Search',
    'cName': 'Company Name',
    'basic': 'Basic',
    'updateTime': 'Update Time',
    'OfficialStore': 'E-Store',
    'english': 'English',
    'russian': 'русск',
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語',
    'china': '简体中文',
    'cht': '繁體中文'
  }
}

export default en