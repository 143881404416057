const ko = {
  locale: 'ko-kr',
  messages: {
    "inputVinBrand": "선반 번호 / 부품 번호 / 브랜드를 입력하십시오.",
    "history": "기록",
    "home": "첫 페이지",
    "catalog": "차종 조회",
    "search": "검색",
    "dragFile": "파일을 이 영역으로 붙여넣거나 드래그하여 식별",
    "selectImage": "이미지 선택",
    "howWorks": "어떻게 작동하는지 이해",
    "more": "추가",
    "helpSection": "도움말",
    "freeTrialDay": "3일 무료 평가판",
    "account": "계정",
    "purchase": "구매",
    "message": "메시지",
    "favorites": "모음집",
    "feedbackMessage": "피드백 메시지",
    "watch": "모음집 목록",
    "coupon": "쿠폰",
    "signOut": "로그아웃",
    "register": "등록",
    "subscribe": "가입",
    "freeSearch": "무료 검색",
    "inviteTips": "버튼을 클릭하여 10 명의 친구를 초대하여 무료 연간 카드를 받습니다.",
    "invite": "초대",
    "freeTrialTips": "Partsbond를 계속 사용하려면 무료 평가판을 시작하십시오.",
    "freeTrial": "무료 평가판",
    "helpVideos": "도움말 비디오",
    "copyright": "Copyright@2020 AutoBond Inc.All Rights Reserved",
    "api": "API",
    "app": "App",
    "about": "우리에 대해",
    "contact": "연락처",
    "and": "및",
    "privacy": "개인 정보 보호 및 약관",
    "feedback": "피드백",
    "writeOpinion": "당신의 의견을 적어서...",
    "sentUsEmail": "사서함 주소:aaronw@autobondinc.com",
    "sentUs": "연락처",
    "iosDownload": "IOS 다운로드",
    "androidDownload": "Android 다운로드",
    "inputPNMR": "부품 번호 / 이름 / 모델 / 설명을 입력하십시오.",
    "view3D": "클릭하여 3D 모델 보기",
    "again": "다시 한 번",
    "pictures": "그림",
    "partsNumber": "부품 번호",
    "partsName": "부품 이름",
    "priceRange": "가격 구간",
    "operation": "작업",
    "email": "메일박스",
    "vCode": "인증 코드",
    "nPassword": "새 암호",
    "cNPassword": "새 암호 확인",
    "submit": "제출",
    "getCode": "인증 코드 가져오기",
    "countdown": "{num}s",
    "inputEmail": "이메일을 입력하십시오.",
    "emailCorrectly": "우편물을 정확하게 기입해 주십시오",
    "inputPassword": "암호를 입력하십시오.",
    "passwordsInconsistent": "입력한 두 암호가 일치하지 않습니다.",
    "sendCodeSuccess": "발송 성공",
    "agreeUserAgreement": "사용자 계약에 동의하십시오.",
    "tryFree": "무료 평가판",
    "tryFreeDay": "로그인 후 무료 평가판을 클릭하여 7일 평가판을 얻습니다",
    "registrationCodeSentSuccess": "등록 코드 발송 성공!1-2분 이내에 코드를 받지 못하면 받은 편지함을 새로 고치고 스팸 폴더를 확인하십시오.",
    "findUs": "우릴 어떻게 찾았어?",
    "iAgree": "동의합니다",
    "userAgreement": "사용자 프로토콜",
    "brand": "브랜드",
    "sampleSearch": "샘플 검색",
    "passwordChangedSuccess": "암호가 성공적으로 변경되었습니다.",
    "passwordChangedFailed": "암호 수정 실패",
    "enterNewName": "새 사용자 이름을 입력하십시오.",
    "userChangedSuccess": "사용자 수정 성공",
    "userChangedFailed": "사용자 정보 수정 실패",
    "fillName": "사용자 이름을 먼저 입력하십시오.",
    "vinBindingSuccee": "선반 번호 바인딩 성공",
    "success": "성공",
    "brandPackage": "자동차 브랜드 조회 패키지",
    "combo": "패키지",
    "validTime": "유효 시간",
    "cancelRenewal": "업데이트 취소",
    "packageContent": "패키지",
    "bindFrame": "번호 1개 더 바인딩",
    "remainingQueries": "남은 조회 수",
    "personalInformation": "개인 정보",
    "loginAccount": "계정 로그인",
    "nickName": "별칭",
    "modify": "수정",
    "bindingEmail": "메일박스 바인딩",
    "loginPassword": "로그인 암호",
    "enterContent": "입력 내용",
    "noCoupons": "쿠폰 없음",
    "discountOperation": "할인 작업",
    "preferentialContent": "혜택 내용",
    "serviceLife": "수명",
    "user": "사용자",
    "remarks": "설명",
    "noPartsCollection": "부품 없음",
    "selectAll": "모두 선택",
    "delete": "삭제",
    "vin": "선반 번호",
    "oe": "OE",
    "detail": "상세 정보",
    "noNews": "새 정보 없음",
    "feedbackDetails": "피드백 정보",
    "feedbackStatus": "피드백 상태",
    "feedbackTime": "피드백 시간",
    "questionType": "문제 유형",
    "problemDescription": "문제 설명",
    "solved": "해결됨",
    "pending": "대기 중",
    "supplementaryQuestion": "보충적 문제",
    "endFeedback": "피드백 종료",
    "cancel": "취소",
    "invalidCoupon": "이 할인 코드를 찾을 수 없습니다.",
    "bindVin": "바인딩 {num} 프레임 번호",
    "annualSubscribe": "연간 구독",
    "currencySymbol": "$",
    "enterNumber": "개별 번호를 차례로 입력하십시오.",
    "boundRemaining": "{hasBound}/{remainingBound} 선반 번호 바인딩 [{has}/{remaining} 남은 선반 번호 바인딩]",
    "boundDescribe1": "하나의 계정에 여러 구독 적용 가능",
    "boundDescribe2": "가입 기간은 1 캘린더 년입니다 (구매 날짜 이후).",
    "boundDescribe3": "VIN은 계정에 영구적으로 바인딩될 수 있습니다.나중에 VIN을 바인딩하려면 계정 세부 정보 내에서 바인딩할 수 있습니다.",
    "allInOne": "ALL-IN-ONE",
    "asiaPack": "ASIA PACK",
    "euroPack": "EURO PACK",
    "usPack": "US PACK",
    "daySubscription": "일일 가입",
    "monthSubscription": "월별 구독",
    "annualSubscription": "연간 구독",
    "use": "사용",
    "howGetCode": "할인 코드를 얻는 방법",
    "payPalWith": "PayPal with",
    "openingPermission": "열린 권한",
    "availablePackages": "사용 가능한 패키지",
    "bestMatch": "최적 일치",
    "priceLowHigh": "낮은 가격에서 높은 가격",
    "priceHighLow": "높은 가격에서 낮은 가격까지",
    "pleaseLoginFirst": "먼저 로그인하십시오.",
    "here3DArea": "3D 영역입니다.",
    "applicableVehicles": "적용 차종",
    "applicableVehicle": "자동차에 적용",
    "interchangeableParts": "대체물",
    "condition": "조건",
    "all": "모두",
    "channel": "채널",
    "location": "위치",
    "partsFound": "개 부품",
    "noData": "데이터 없음",
    "carsModels": "모델 (연대)",
    "year": "년",
    "market": "시장",
    "oeNumber": "OE 번호",
    "partName": "부품 이름",
    "count": "수",
    "partsPrice": "부품 가격 비교",
    "directReplacement": "직접 교체",
    "indirectReplacement": "간접 대체",
    "priceTrend": "가격 추세",
    "partPicture": "부품 그림",
    "brands": "브랜드",
    "replacement": "교체",
    "applicableVeh": "적용 Veh",
    "supplier": "공급업체",
    "getIt": "가져오기",
    "go": "Go",
    "findResults": "{num} 에 대한 결과를 찾습니다.",
    "partOe": "OE",
    "mainGroup": "주 그룹",
    "subGroup": "그룹",
    "group": "그룹",
    "configDetails": "구성 상세 정보",
    "selectGroup": "그룹 선택",
    "conflg": "구성",
    "picture": "그림",
    "mg": "MG",
    "sg": "SG",
    "illNo": "ILL-NO",
    "name": "이름",
    "remark": "설명",
    "model": "차종",
    "selectConfigurationInformation": "구성 정보 선택",
    "groupOne": "1조!",
    "lastGroup": "마지막 팀!",
    "list": "목록",
    "back": "반환",
    "switchGroup": "스위치",
    "notFilter": "필터링 안 함",
    "filter": "여과기",
    "config": "구성",
    "pos": "POS",
    "qty": "QTY",
    "addWatch": "즐겨찾기 목록 추가",
    "brandPart": "브랜드 부품",
    "component": "구성 요소",
    "disclaimer": "선언: 질의 결과는 참조용으로만 제공됩니다.",
    "partDetail": "부품 상세 정보",
    "vinNotAvailable": "차량 식별 코드 사용 불가",
    "partNotAvailable": "부품 번호를 사용할 수 없습니다.",
    "lackModels": "부족한 모델",
    "dataNotComplete": "데이터 불완전",
    "dataError": "데이터 오류",
    "dataNotNew": "데이터가 충분하지 않음",
    "slowSpeed": "속도가 느리다",
    "noUniqueness": "고유하지 않음",
    "other": "기타",
    "selectQuestion": "질문 선택",
    "close": "닫기",
    "howVin": "랙 번호는 어떻게 확인할 수 있습니까?",
    "howVin1": "선반 번호를 찾아 차체 앞창 유리에 차량 식별 코드를 기록하다",
    "howVin2": "선반 번호를 입력하거나 클릭하여 사진을 업로드하여 선반 번호를 식별합니다.",
    "howVin3": "질의 아이콘을 클릭하여 선반 번호를 질의합니다.",
    "howVin4": "브랜드 이름을 입력하고 질의 아이콘 을 클릭하여 질의",
    "findParts": "부품을 어떻게 찾습니까?",
    "findParts1": "기본 구성 정보를 보려면 마우스를 주차 프레임 번호에 놓습니다.",
    "findParts2": "이 아이콘을 클릭하여 3D 모델을 사용하여 차체 부품, 섀시 부품, 엔진 부품 조회",
    "findParts3": "마스터 그룹과 그룹을 선택하여 부품 목록으로 이동",
    "findParts4": "를 클릭하여 기본 그룹 그룹 및 상자 번호 질의를 다시 선택합니다.",
    "findParts5": "분해 뷰의 부품 번호를 클릭하여 부품 선택",
    "howPart": "부품 번호 조회는 어떻게 사용합니까?",
    "howPart1": "이미지 인식 부품 번호를 입력하거나 업로드하고 검색을 클릭하여 조회",
    "howPart2": "내 컬렉션에 부품을 수집하려면 아이콘을 클릭하십시오. 부품 가격이 변경되면 이메일 알림을 받게 됩니다",
    "howPart3": "이 부품의 최근 가격 추세를 보려면 아이콘을 클릭하십시오.",
    "noHelp": "도움 필요 없음",
    "understood": "알았어",
    "nextStep": "다음 단계",
    "clickCheckInvitation": "초대장 상태를 보려면 여기를 클릭하십시오.",
    "inviteFriendsGetPackage": "10명의 친구를 초청하여 연간 패키지를 획득하였는데, 가치가 540달러이다!",
    "emailAddress": "이메일 주소",
    "status": "상태",
    "redeem": "환전",
    "inviteRewardTips": "<p>신청 확인하세요 <br /> <em> All-in-One 연간 구매 </em> 보상 </p> <p> 현재 유효한 패키지가 있으면 보상은 만기 시 자동으로 교환됩니다",
    "currentSubscriptionExpires": "현재 패키지 만료일은",
    "confirm": "확인",
    "putAway": "Put Away",
    "seeAll": "See All",
    "pageCannotEmpty": "페이지 수는 비워둘 수 없습니다.",
    "pleaseRegisterSignin": "더 많은 차량 데이터를 검색하려면 등록 / 로그인하십시오.",
    "price": "가격",
    "quantity": "수량",
    "pleaseRL": "등록 후 로그인하십시오.",
    "sign": "로그인",
    "stay": "비밀번호 기억하기",
    "forget": "암호를 잊었습니다.",
    "password": "암호",
    "RFFVS": "등록 무료 검색 vin",
    "cName": "회사 이름",
    "basic": "기본 정보",
    "updateTime": "업데이트 시간",
    'OfficialStore': '공식 상점',
    "english": "English",
    "russian": "русск",
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語',
    'china': '简体中文',
    'cht': '繁體中文'
  }
}

export default ko