const es = {
  locale: 'es-ES',
  messages: {
    "inputVinBrand": "Introduzca el número de marco / número de pieza / marca",
    "history": "Registros históricos",
    "home": "Página principal",
    "catalog": "Modelo",
    "search": "Buscar",
    "dragFile": "Pegar o arrastrar archivos a esta área para su reconocimiento",
    "selectImage": "Seleccionar imagen",
    "howWorks": "Aprende cómo funciona",
    "more": "Más",
    "helpSection": "Ayudar",
    "freeTrialDay": "Prueba gratuita durante 3 días",
    "account": "Cuenta",
    "purchase": "Compra",
    "message": "Noticias",
    "favorites": "Colección",
    "feedbackMessage": "Mensaje de retroalimentación",
    "watch": "Lista de colecciones",
    "coupon": "Cupón",
    "signOut": "Salir",
    "register": "Registro",
    "subscribe": "Suscripción",
    "freeSearch": "Búsqueda gratuita",
    "inviteTips": "Haga clic en el botón para invitar a 10 amigos a obtener tarjetas anuales gratuitas",
    "invite": "Invitación",
    "freeTrialTips": "Para seguir usando partsbond, comience a probarlo de forma gratuita",
    "freeTrial": "Prueba gratuita",
    "helpVideos": "Vídeo de ayuda",
    "copyright": "Copyright@2020 Autobond Inc. All Rights Reserved",
    "api": "API",
    "app": "App",
    "about": "Sobre nosotros",
    "contact": "Contáctanos",
    "and": "Y",
    "privacy": "Privacidad y cláusulas",
    "feedback": "Comentarios",
    "writeOpinion": "Escribe tu opinión...",
    "sentUsEmail": "Dirección de correo electrónico: aaronw@autobondinc.com",
    "sentUs": "Contáctanos",
    "iosDownload": "Descargas de Ios",
    "androidDownload": "Descarga de Android",
    "inputPNMR": "Introduzca el número de pieza / nombre / Modelo / nota",
    "view3D": "Haga clic para ver el modelo 3D",
    "again": "Una vez más",
    "pictures": "Imagen",
    "partsNumber": "Número de pieza",
    "partsName": "Nombre de la pieza",
    "priceRange": "Rango de precios",
    "operation": "Operación",
    "email": "Buzón",
    "vCode": "Código de verificación",
    "nPassword": "Nueva contraseña",
    "cNPassword": "Determinar la nueva contraseña",
    "submit": "Presentación",
    "getCode": "Obtener el Código de verificación",
    "countdown": "{num}s",
    "inputEmail": "Introduzca su buzón",
    "emailCorrectly": "Por favor, rellene el correo correctamente.",
    "inputPassword": "Por favor, introduzca la contraseña",
    "passwordsInconsistent": "Las dos contraseñas introducidas son inconsistentes",
    "sendCodeSuccess": "Envío exitoso",
    "agreeUserAgreement": "Por favor, acepte el Acuerdo de usuario",
    "tryFree": "Prueba las piezas de forma gratuita",
    "tryFreeDay": "Después de iniciar sesión, haga clic en prueba gratuita para obtener 7 días de prueba.",
    "registrationCodeSentSuccess": "¡¡ el Código de registro fue enviado con éxito! Si no recibe el Código en 1 - 2 minutos, intente actualizar su bandeja de entrada y revisar su carpeta de spam.",
    "findUs": "¿¿ cómo nos encontraste?",
    "iAgree": "Estoy de acuerdo.",
    "userAgreement": "Protocolo de usuario",
    "brand": "Marca",
    "sampleSearch": "Búsqueda de ejemplo",
    "passwordChangedSuccess": "Cambio de contraseña exitoso",
    "passwordChangedFailed": "Falló la modificación de la contraseña",
    "enterNewName": "Introduzca un nuevo nombre de usuario",
    "userChangedSuccess": "Modificación exitosa del usuario",
    "userChangedFailed": "Falló la modificación de la información del usuario",
    "fillName": "Por favor, rellene el nombre de usuario primero.",
    "vinBindingSuccee": "El número de marco está vinculado con éxito.",
    "success": "éxito",
    "brandPackage": "Paquete de consulta de marca de automóviles",
    "combo": "Combinación",
    "validTime": "Tiempo de validez",
    "cancelRenewal": "Cancelar actualización",
    "packageContent": "Paquete",
    "bindFrame": "Vincular otro número",
    "remainingQueries": "Número de consultas restantes",
    "personalInformation": "Información personal",
    "loginAccount": "Iniciar sesión en la cuenta",
    "nickName": "Alias",
    "modify": "Modificación",
    "bindingEmail": "Buzón vinculado",
    "loginPassword": "Contraseña de inicio de sesión",
    "enterContent": "Introducir contenido",
    "noCoupons": "No hay cupones",
    "discountOperation": "Operación de descuento",
    "preferentialContent": "Contenido preferencial",
    "serviceLife": "Vida útil",
    "user": "Usuarios",
    "remarks": "Nota",
    "noPartsCollection": "Sin piezas",
    "selectAll": "Elegir todo",
    "delete": "Eliminar",
    "vin": "Número de marco",
    "oe": "Oe",
    "detail": "Detalles",
    "noNews": "No hay noticias",
    "feedbackDetails": "Información de retroalimentación",
    "feedbackStatus": "Estado de retroalimentación",
    "feedbackTime": "Tiempo de retroalimentación",
    "questionType": "Tipo de problema",
    "problemDescription": "Descripción del problema",
    "solved": "Resuelto",
    "pending": "Esperar",
    "supplementaryQuestion": "Preguntas complementarias",
    "endFeedback": "Comentarios finales",
    "cancel": "Cancelación",
    "invalidCoupon": "No se encontró este Código de descuento",
    "bindVin": "Vinculado ('num') número de marco",
    "annualSubscribe": "Suscripción anual",
    "currencySymbol": "Dólares",
    "enterNumber": "Por favor, introduzca un solo número a su vez.",
    "boundRemaining": "(...) vinculación del número de marco (...) / (...) restante de la vinculación del número de marco]",
    "boundDescribe1": "Varias suscripciones se pueden aplicar a una cuenta",
    "boundDescribe2": "La suscripción es válida por 1 año civil (a partir de la fecha de compra)",
    "boundDescribe3": "La vinculación entre el usuario registrado y el usuario registrado es permanente; Si desea vincular su vin (s) más tarde, puede hacerlo en los detalles de su cuenta.",
    "allInOne": "All - in - One",
    "asiaPack": "Asia Pack",
    "euroPack": "Euro Pack",
    "usPack": "US Pack",
    "daySubscription": "Suscripción por día",
    "monthSubscription": "Suscripción mensual",
    "annualSubscription": "Suscripción anual",
    "use": "Uso",
    "howGetCode": "Cómo obtener el Código de descuento",
    "payPalWith": "PayPal con",
    "openingPermission": "Permisos abiertos",
    "availablePackages": "Paquetes disponibles",
    "bestMatch": "Mejor coincidencia",
    "priceLowHigh": "El precio pasa de bajo a alto",
    "priceHighLow": "Los precios van de altos a bajos",
    "pleaseLoginFirst": "Por favor, inicie sesión primero",
    "here3DArea": "Esta es la zona 3D.",
    "applicableVehicles": "Modelo aplicable",
    "applicableVehicle": "Adecuado para automóviles",
    "interchangeableParts": "Piezas de reemplazo",
    "condition": "Condiciones",
    "all": "Todo",
    "channel": "Canales",
    "location": "Posición",
    "partsFound": "Piezas individuales",
    "noData": "Sin datos",
    "carsModels": "Modelo (edad)",
    "year": "Año",
    "market": "Mercado",
    "oeNumber": "Oe",
    "partName": "Nombre de la pieza",
    "count": "Número",
    "partsPrice": "Comparación de precios de las piezas",
    "directReplacement": "Sustitución directa",
    "indirectReplacement": "Sustitución indirecta",
    "priceTrend": "Tendencias de precios",
    "partPicture": "Imagen de la pieza",
    "brands": "Marca",
    "replacement": "Sustitución",
    "applicableVeh": "Se aplica veh",
    "supplier": "Proveedores",
    "getIt": "Acceso",
    "go": "Go",
    "findResults": "Encontrar resultados para usted sobre (...).",
    "partOe": "Oe",
    "mainGroup": "Grupo principal",
    "subGroup": "Subdirector",
    "group": "Grupo",
    "configDetails": "Detalles de la configuración",
    "selectGroup": "Elegir el maestro",
    "conflg": "Configuración",
    "picture": "Imagen",
    "mg": "MG",
    "sg": "SG",
    "illNo": "Il - No",
    "name": "Nombre",
    "remark": "Nota",
    "model": "Modelo",
    "selectConfigurationInformation": "Seleccionar información de configuración",
    "groupOne": "¡Grupo uno!",
    "lastGroup": "¡El último grupo!",
    "list": "Lista",
    "back": "Volver",
    "switchGroup": "Interruptor",
    "notFilter": "Sin filtro",
    "filter": "Filtro",
    "config": "Configuración",
    "pos": "Pos",
    "qty": "Qty",
    "addWatch": "Añadir a la lista de colecciones",
    "brandPart": "Piezas de marca",
    "component": "Componentes",
    "disclaimer": "Declaración: los resultados de la consulta son solo para referencia.",
    "partDetail": "Detalles de las piezas",
    "vinNotAvailable": "El Código de identificación del vehículo no está disponible",
    "partNotAvailable": "Número de pieza no disponible",
    "lackModels": "Falta de modelos",
    "dataNotComplete": "Datos incompletos",
    "dataError": "Error de datos",
    "dataNotNew": "Los datos no son lo suficientemente nuevos",
    "slowSpeed": "Velocidad lenta",
    "noUniqueness": "No es el único",
    "other": "Otros",
    "selectQuestion": "Preguntas de selección",
    "close": "Cierre",
    "howVin": "¿¿ Cómo ver el número del bastidor?",
    "howVin1": "Encontrar el número de marco y registrar el Código de identificación del vehículo en el vidrio de la ventana delantera de la carrocería",
    "howVin2": "Introduzca el número de caja o haga clic para cargar la imagen, identifique el número de caja",
    "howVin3": "Haga clic en el icono de consulta para consultar el número de cuadro",
    "howVin4": "Introduzca el nombre de la marca y haga clic en el icono de consulta para consultar",
    "findParts": "¿¿ cómo encontrar las piezas?",
    "findParts1": "Deje el ratón sobre el número de estante de estacionamiento para ver la información básica de configuración",
    "findParts2": "Haga clic en este icono para consultar los componentes de la carrocería, los componentes del chasis y los componentes del motor utilizando un modelo 3D.",
    "findParts3": "Seleccione el grupo principal y el Grupo para ingresar a la lista de piezas",
    "findParts4": "Haga clic para volver a seleccionar el grupo principal y la consulta del número de cuadro",
    "findParts5": "Haga clic en el número de pieza de la vista de descomposición para seleccionar la pieza",
    "howPart": "¿¿ cómo usar la consulta del número de pieza?",
    "howPart1": "Introduzca o suba la imagen para identificar el número de la pieza, haga clic en buscar para consultar",
    "howPart2": "Haga clic en el icono para recoger las piezas en mi colección y cuando el precio de las piezas cambie, recibirá una notificación por correo electrónico.",
    "howPart3": "Haga clic en el icono para ver la tendencia reciente del precio de la pieza.",
    "noHelp": "No se necesita ayuda",
    "understood": "Lo Tengo.",
    "nextStep": "Siguiente paso",
    "clickCheckInvitation": "Haga clic aquí para ver el Estado de la invitación",
    "inviteFriendsGetPackage": "¡¡ invitar a 10 amigos a recibir el paquete anual, por valor de 540 dólares!",
    "emailAddress": "Dirección de correo electrónico",
    "status": "Estado",
    "redeem": "Cambio",
    "inviteRewardTips": "< p > por favor, confirme la solicitud < PR / > < em > All - in - one annual Buy < em > Reward < p > Si tiene un paquete actualmente válido, su recompensa se canjeará automáticamente al expirar",
    "currentSubscriptionExpires": "La fecha de caducidad de su paquete actual es",
    "confirm": "Confirmación",
    "putAway": "Put away",
    "seeAll": "See all",
    "pageCannotEmpty": "El número de páginas no puede estar vacío",
    "pleaseRegisterSignin": "Por favor, registre / inicie sesión para buscar más datos del vehículo",
    "price": "Precio",
    "quantity": "Cantidad",
    "pleaseRL": "Por favor, inicie sesión después de registrarse",
    "sign": "Iniciar sesión",
    "stay": "Mantenga el inicio de sesión",
    "forget": "Olvida la contraseña",
    "password": "Contraseña",
    "RFFVS": "Registro para buscar vin gratis",
    "cName": "Nombre de la empresa",
    "basic": "Información básica",
    "updateTime": "Tiempo de actualización",
    'OfficialStore': 'Tienda oficial',
    'english': 'English',
    'russian': 'русск',
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語',
    'china': '简体中文',
    'cht': '繁體中文'
  }
}

export default es