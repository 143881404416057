import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import Loading from '@/components/Loading'
import { useHistory } from 'react-router-dom'
import Toast from '@/components/Toast'
import accountApi from '@/api/account'
import { getUrlParams } from '@/utils/utils'
import styles from './my.module.scss'
import MainContext from '@/context/MainContext'


const Paypal: React.FC = () => {
  const { setStore } = useContext(MainContext)
  const intl = useIntl()
  const { token, ba_token } = getUrlParams()
  const history = useHistory()
  useEffect(() => {
    getOrder()
  },[])
  
  const getOrder = () => {
    accountApi.paypalOrder({token, ba_token}).then((result: any)=>{
      if(result.code === 1 && result.data){
        paycheck(result.data, token)
      }
    })
  }
  const paycheck = (param: any, tk: string) => {
    const { token } = getUrlParams()
    if(token === tk){
      accountApi.paycheck(param).then((result: any)=>{
        const { token } = getUrlParams()
        if(token === tk){
          if(result.code === 1 && result.data.status === 2){
            setTimeout(()=>{
              paycheck(param, tk)
            }, 3000)
          }else {
            Toast(result.data.message)
            getUserInfo()
            history.push('/')
          }
        }
      })
    }
    
  }

  const getUserInfo = () => {
    accountApi.getUserInfo().then((result: {code: number, data: object}) => {
      if(result.code === 1) {
        setStore({value: result.data, type: 'userInfo'})
        queryCount()
      }
    })
  }

  const queryCount = () => {
    accountApi.queryCount().then((result: any)=>{
      if(result.code === 1 && result.data?.freecount) {
        setStore({value: result.data.freecount, type: 'freecount'})
      }
    })
  }
  return (
    <div className={styles.paypal}>
      <div>
        <Loading visible />
        <p>{intl.formatMessage({id: 'openingPermission'})} ...</p>
      </div>
      
    </div>
  )
}



export default Paypal
