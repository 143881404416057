import React, { useState, useEffect, useReducer, useContext, useRef } from 'react'
import moment from 'moment-timezone'
import { useIntl } from 'react-intl'
import MainContext from '@/context/MainContext'
import Mymenu from './Mymenu'
import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Button from "@/components/Button"
import Toast from '@/components/Toast'
import Spin from '@/components/Spin'
import Loading from '@/components/Loading'
import account from '@/api/account'


import styles from './my.module.scss'


type resultItem = {
  code: number,
  data: object
}
interface InfoItem {
  [index: string]: any
}
type changePwdState = {
  [index: string] : string
}
type changePwdAction = 
| {type: 'nick_name', value: string}
| {type: 'password', value: string}
| {type: 'aPassword', value: string}
| {type: 'verify_code', value: string}
| {type: 'username', value: string}
const reducer = (state: changePwdState, action: changePwdAction) => {
  switch(action.type) {
    case 'nick_name':
      return {
        ...state,
        nick_name: action.value
      }
    case 'password':
      return {
        ...state,
        password: action.value
      }
    case 'aPassword':
      return {
        ...state,
        aPassword: action.value
      }
    case 'verify_code':
      return {
        ...state,
        verify_code: action.value
      }
    case 'username': 
      return {
        ...state,
        username: action.value
      }
    default:
      return state
  }
}
const initVal = {
  nick_name: '',
  password: '',
  aPassword: '',
  verify_code: '',
  username: ''
}
const Account: React.FC = (props) => {
  const intl = useIntl()
  const { store, setStore } = useContext(MainContext)
  const { userInfo } = store
  const [cancel,setCancel] = useState<boolean>(false)
  const [vinVisible, setVinVisible] = useState<boolean>(false)
  const [cType, setCType] = useState<string>('')
  // const [userInfo, setUserInfo] = useState<any>({})
  const [packInfo, setPackInfo] = useState<InfoItem>({})
  const [num, setNum] = useState<number>(0)
  const [changeVal, setChangeVal] = useReducer(reducer, initVal)
  const [change, setChange] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [editLoading, setEditLoading] = useState<boolean>(false)
  const [codeLoading, setCodeLoading] = useState<boolean>(false)
  const [cancelRenewalLoading, setCancelRenewalLoading] = useState<boolean>(false)
  const [vinList, setVinList] = useState<any>([])

  
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    getUserVins()
  },[])

  const getUserVins = () => {
    account.getUserVins().then((res: { code: number, data: any }) => {
      if(res.code === 1) {
        setVinList(res.data)
      }
    })
  }

  function openModal(key: string){
    let otherInfo = {...userInfo} 
    if(key === 'nick_name') {
      changeVal.nick_name = otherInfo.data.nick_name || otherInfo.data.full_name
      setChange(!change)
    }
    if(key === 'password') {
      changeVal.username = otherInfo.data.username
      setChange(!change)
    }
    setCType(key)
    setCancel(true)
  }
  function editCancel() {
    setCancel(false)
    clearInfo()
  }
  function clearInfo() {
    changeVal.password = ''
    changeVal.aPassword = ''
    changeVal.nick_name = ''
    changeVal.verify_code = ''
    changeVal.username = ''
    setChange(!change)
  }

  function submit(){
    postUserInfo()
  }

  const getPackInfo = () => {
    setLoading(true)
    account.getPackInfo().then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        // if(res.data.validate_time){
        //   let date = moment.unix(res.data.validate_time).format('YYYY-MM-DD hh:mm:ss')
        //   res.data.date = moment(date).utc().local().format('HH:mm:ss YYYY-MM-DD')
        // }
    
        setPackInfo(res.data)
      }
    })
  }
  useEffect(() => {
    getPackInfo()
  },[])
  const postUserInfo = () => {
    let param
    if(cType === 'password') {
      if(changeVal.password !== changeVal.aPassword) {
        Toast(intl.formatMessage({id: 'passwordsInconsistent'}), 'warning')
        return false
      }
      // param = {
      //   'password': changeVal.password,
      //   "username": changeVal.username,
      //   "verify_code": changeVal.verify_code
      // }
      param = {
        sms_code: changeVal.verify_code,
        password: changeVal.password,
        repwd: changeVal.password
      }
      setEditLoading(true)
      account.changeInfo(param).then((res: resultItem) => {
        setEditLoading(false)
        if(res.code === 1) {
          setCancel(false)
          clearInfo()
          Toast(intl.formatMessage({id: 'passwordChangedSuccess'}))
        }else {
          Toast(intl.formatMessage({id: 'passwordChangedFailed'}), 'error')
        }
      })
    }else {
      // param = {'nick_name' : changeVal.nick_name}
      param = {
        nickname: changeVal.nick_name,
        profile_type: 'full_name'
      }
      if(!changeVal.nick_name) {
        Toast(intl.formatMessage({id: 'enterNewName'}), 'warning')
        return false
      }
      setEditLoading(true)
      account.changeInfo(param).then((result: resultItem) => {
        setEditLoading(false)
        if(result.code === 1) {
          setCancel(false)

          let temp = {...userInfo} 
          temp.data.full_name = changeVal.nick_name
          setStore({value: temp, type: 'userInfo'})

          clearInfo()
          Toast(intl.formatMessage({id: 'userChangedSuccess'}))
          
          // sessionStorage.removeItem('userInfo')
          // window.location.reload()
        }else {
          Toast(intl.formatMessage({id: 'userChangedFailed'}), 'error')
        }
      })
    }
  }
  const getCode = () => {
    if(!changeVal.username) {
      Toast(intl.formatMessage({id: 'fillName'}), 'warning')
      return false
    }
    setCodeLoading(true)
    account.sendVcode({
      email: changeVal.username,
      email_type: 'user_reset_pwd'
    }).then((res: {code: number}) => {
      setCodeLoading(false)
      if(res.code === 1) {
        setNum(60)
      }
    })
  }
  const setInput = (val: any) => {
    if(inputRef.current) {
      inputRef.current.value = val.replace(/\s*/g,"").toLocaleUpperCase()
    }
  }
  const addVin = () => {
    let param: any = {}
    if(inputRef.current) {
      param = {
        vin: inputRef.current.value
      }
      for(let i = 0;i < vinList.length; i++) {
        if(!vinList[i].vin) {
          param.vin_id = vinList[i].vin_id
          break
        }
      }
    }
    account.addUserVins(param).then((res: { code : number }) => {
      if(res.code === 1) {
        getUserVins()
        setVinVisible(false)
        Toast(intl.formatMessage({id: 'vinBindingSuccee'}))
      }
    })
  }
  const checkBindVin = () => {
    let check = false
    vinList.forEach((item: any) => {
      if(!item.vin) {
        check = true
      }
    })
    return check
  }
  useEffect(() => {
    if(num) {
      setTimeout(() => {
        setNum(num - 1)
      },1000)
    }
  },[num])

  const cancelRenewal = () => {
    setCancelRenewalLoading(true)
    account.cancelRenewal().then((result: any)=>{
      setCancelRenewalLoading(false)
      if(result.code === 1){
        let temp = {...packInfo}
        temp.issubscribe = 0
        setPackInfo(temp)
        Toast(intl.formatMessage({id: 'success'}))
      }
    })
  }


  const codeDisabled = !num ? false : true
  const submitDisabled = changeVal.username && changeVal.username.indexOf("@") > 0 && changeVal.verify_code && changeVal.password && changeVal.aPassword ? false : true
  return (
    <Mymenu
      tab={1}
    >
      <Modal
        visible={vinVisible}
        cancel={setVinVisible}
        title={'Bind VIN'}
        width={400}
      >
        <div style={{padding: 20}}>
          <Input ref={inputRef} onChange={(val: any) => setInput(val)} placeholder="Enter VIN code" />
          <Button onClick={() => addVin()} type="primary"  style={{width: '100%',marginTop: 15}}>{intl.formatMessage({id: 'submit'})}</Button>
        </div>
      </Modal>
      <div className={styles.account}>
        <Spin loading={loading}></Spin>
        <div className={styles.accoutHeader}>
          <h3 >{intl.formatMessage({id: 'brandPackage'})}</h3>
          <div className={styles.aItem}>
            <span className={styles.f1}>{intl.formatMessage({id: 'combo'})}</span>
            <span>{packInfo.package || ''}</span>
            <span></span>
          </div>
          <div className={styles.aItem}>
            <span className={styles.f1}>{intl.formatMessage({id: 'validTime'})}</span>
            <span>{packInfo.valid_time || ''}</span>
            <span>
              {
                packInfo.issubscribe ? (
                  <button disabled={cancelRenewalLoading} className={styles.link} onClick={()=>cancelRenewal()}><Loading visible={cancelRenewalLoading} type="min" color="#FF5502" /> <em>{intl.formatMessage({id: 'cancelRenewal'})}</em></button>
                ): null
              }
            </span>
          </div>
          <div className={styles.aItem + ' ' + styles.other} style={{border:'none'}}>
            {
              packInfo.package_type === 'vin' ? (
                <>
                  <span className={styles.f1}>{intl.formatMessage({id: 'packageContent'})}</span>
                  <span>
                    {
                      vinList.map((item: any, index: number) => {
                        return item.vin && <span key={index}>{item.vin}</span>
                      })
                    }
                  </span>
                  {checkBindVin() ? <span onClick={() => setVinVisible(true)} style={{textAlign:'right',color: '#1890FF',cursor:'pointer'}}>{intl.formatMessage({id: 'bindFrame'})}</span> : <span></span>}
                </>
              ): (
                <>
                  <span className={styles.f1}>{intl.formatMessage({id: 'remainingQueries'})}</span>
                  <span >{packInfo.query || ''}</span>
                  <span></span>
                </>
              )
            }
          </div>
        </div>
        <div className={styles.accountContent}>
          <h3>{intl.formatMessage({id: 'personalInformation'})}</h3>
          <div className={styles.aItem}>
            <span className={styles.f1}>{intl.formatMessage({id: 'loginAccount'})}</span>
            <span>{userInfo.data?.username}</span>
            <span style={{textAlign:'right'}}></span>
          </div>
          <div className={styles.aItem}>
            <span className={styles.f1}>{intl.formatMessage({id: 'nickName'})}</span>
            <span>{userInfo.data?.nick_name || userInfo.data?.full_name}</span>
            <span onClick={() => openModal('nick_name') } style={{textAlign:'right',color: '#1890FF',cursor:'pointer'}}>{intl.formatMessage({id: 'modify'})}</span>
          </div>
          <div className={styles.aItem} >
            <span className={styles.f1}>{intl.formatMessage({id: 'bindingEmail'})}</span>
            <span>{userInfo.data?.email}</span>
            <span ></span>
          </div>
          <div className={styles.aItem} >
            <span className={styles.f1}>{intl.formatMessage({id: 'loginPassword'})}</span>
            <span>******</span>
            <span onClick={() => openModal('password') } style={{textAlign:'right',color: '#1890FF',cursor:'pointer'}}>{intl.formatMessage({id: 'modify'})}</span>
          </div>
        </div>
      </div>
      <Modal
        title={(cType === 'nick_name' ? intl.formatMessage({ id: 'Modify nickname' }) : intl.formatMessage({ id: 'Modify password' }))}
        visible={cancel}
        cancel={editCancel}
      >
        <div className={styles.modify}>
          {
            cType === 'nick_name' ? (
              <>
                <div className={styles.inputItem}>
                  <Input type='text' size="large" placeholder={intl.formatMessage({ id: "New nick_name" })} value={changeVal.nick_name} onChange={(value: string) => setChangeVal({type: 'nick_name', value})} />
                </div>
                <div className={styles.inputItem}>
                  <Button type="primary" loading={editLoading} disabled={!changeVal.nick_name} style={{width:'100%',height:40,boxSizing:'border-box',marginBottom:20,fontSize:16}} onClick={submit}>{intl.formatMessage({id: 'submit'})}</Button>
                </div>
              </>
            ): cType === 'password' ? (
              <>
                <div className={styles.inputItem}>
                  <Input type="text" disabled value={changeVal.username} size="large" placeholder="username"  onChange={(value: string) => setChangeVal({type: 'username', value}) } />
                </div>
                <div className={styles.inputItem}>
                  <Input type="text" value={changeVal.verify_code} size="large" placeholder={intl.formatMessage({ id: "verify_code" })}  onChange={(value: string) => setChangeVal({type: 'verify_code', value}) } />
                  <Button loading={codeLoading} disabled={codeDisabled} type="primary" style={{width: 119,margin:0,marginLeft:12,textAlign:'center',fontSize:18,height:40}}  onClick={() => getCode()}>{num ? intl.formatMessage({id:'countdown'}, {num: num}) : intl.formatMessage({id: 'getCode'})}</Button>
                </div>
                <div className={styles.inputItem}>
                  <Input type="password" value={changeVal.password} size="large" placeholder={intl.formatMessage({ id: "New password" })}  onChange={(value: string) => setChangeVal({type: 'password', value}) } />
                </div>
                <div className={styles.inputItem}>
                  <Input type="password" value={changeVal.aPassword} size="large" placeholder={intl.formatMessage({ id: "Confirm new password" })} onChange={(value: string) => setChangeVal({type: 'aPassword', value})} />
                </div>
                <div className={styles.inputItem}>
                  <Button disabled={submitDisabled} loading={editLoading} type="primary" style={{width:'100%',height:40,boxSizing:'border-box',marginBottom:20,fontSize:16}} onClick={submit}>{intl.formatMessage({id: 'submit'})}</Button>
                </div>
              </>
            ): null
          }
        </div>
      </Modal> 
      
    </Mymenu>
  )
}



export default Account;
